import React, { FC, useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ProposalDetailBullet } from '../model';

import ProposalDetailBulletFormEdit from './ProposalDetailBulletFormEdit';
import ProposalDetailBulletFormNew from './ProposalDetailBulletFormNew';

interface DetailItemsListProps {
  proposalDetailBullets: ProposalDetailBullet[];
  bulletableId: string;
  category: string;
  fetchProposalDetail: () => void;
}

const DetailItemsList: FC<DetailItemsListProps> = (props) => {
  let { proposalDetailBullets, bulletableId, category, fetchProposalDetail } = props;

  let [newBulletOpen, setNewBulletOpen] = useState<boolean>(false);

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 5 }}>
        <Typography variant="h5" component="div">
          {category}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {proposalDetailBullets?.map((item, index) => (
          <ProposalDetailBulletFormEdit
            key={index}
            proposalDetailBullet={item}
            fetchProposalDetail={fetchProposalDetail}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        {newBulletOpen ? (
          <ProposalDetailBulletFormNew
            proposalDetailBullet={{
              description: 'New Bullet Point',
              bulletable_id: bulletableId,
              bulletable_type: 'ProposalDetail',
              category: category,
            }}
            setNewBulletOpen={setNewBulletOpen}
            fetchProposalDetail={fetchProposalDetail}
          />
        ) : (
          <Button variant="contained" color="secondary" onClick={() => setNewBulletOpen(true)}>
            + Bullet
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default DetailItemsList;
