import React from 'react';
import { CircularProgress, Box, Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { UsersContext } from '../contexts/UsersContext';
import UserForm from '../components/UserForm';

const UserEdit: React.FunctionComponent = () => {
  const { userId } = useParams<Record<string, string>>();
  const history = useHistory();
  const { fetchUser, user, userLoading } = React.useContext(UsersContext);

  React.useEffect(() => {
    fetchUser(userId);
  }, [userId]);

  return userLoading && user?.id ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading User...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
        onClick={() => history.push('/admin/user_management')}
      >
        ← Return to Users
      </Typography>
      <Typography variant="h5" component="h5">
        Edit User Details
      </Typography>
      <Grid container flex-direction="column">
        <Grid item xs={10}>
          <UserForm user={user} onSuccess={() => history.push('/admin/equipment_costs')} />
        </Grid>
      </Grid>
    </>
  );
};

export default UserEdit;
