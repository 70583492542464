import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  Modal,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  CircularProgress,
  Typography,
  IconButton,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import InformationField from '../../form/components/InformationField';
import { EstimateCustomersContext } from '../contexts/EstimateCustomersContext';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import axios from '../../utils/axios.utils';

const monthOptions = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

interface AssetFormProps {
  salesEstimateId: string;
}

const AssetForm = (props: AssetFormProps) => {
  const { salesEstimateId } = props;

  const history = useHistory();

  const { preexistingRooftopAssets } = useContext(EstimateCustomersContext);
  const { salesEstimate } = useContext(EstimateDetailsContext);
  const [loading, setLoading] = React.useState(false);
  const [xoiButtonText, setXoiButtonText] = React.useState('Import From XOi');

  const [formOptions, setFormOptions] = useState<any>({});
  const [isPollingXoi, setPollingXoi] = useState(false);
  const [xoiNameplates, setXoiNameplates] = useState<any>([]);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [missingInfo, setMissingInfo] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<any>({});
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
    setPollingXoi(false);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setPollingXoi(false);
  };

  const fetchFormOptions = async () => {
    try {
      await axios.get('assets/new').then((response: any) => {
        setFormOptions(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchXoiNameplates = async () => {
    const response = await axios.get(`/xoi_jobs/${salesEstimate.xoi_job_id}`);
    setXoiNameplates(response.data);
  };

  const addPreexistingAssetToEstimateAssets = (row) => {
    let newFormValues = [...formValues];
    newFormValues.push(row);
    setFormValues(newFormValues);
  };

  const AddToEstimateButton: React.FC<{ row: any }> = ({ row }) => {
    let shouldDisable = formValues?.some((asset) => asset.asset_id === row.asset_id);

    return (
      <Button onClick={() => addPreexistingAssetToEstimateAssets(row)} variant="contained" disabled={shouldDisable}>
        Add To Estimate
      </Button>
    );
  };

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Asset',
      selector: 'asset_number',
      sortable: true,
    },
    {
      name: 'Manufacturer',
      selector: 'manufacturer',
      sortable: true,
    },
    {
      name: 'Model',
      selector: 'model_number',
      sortable: true,
    },
    {
      name: 'Serial',
      selector: 'serial_number',
      sortable: true,
    },
    {
      name: 'Age',
      selector: 'age',
      sortable: true,
    },
    {
      name: 'V/P',
      selector: 'phase',
      sortable: true,
      grow: 0.5,
      cell: (row) => <span>{row.voltage && row.phase ? `${row.voltage}/${row.phase}` : 'Unknown'}</span>,
    },
    {
      name: 'Degraded EER',
      selector: 'phase',
      sortable: true,
      grow: 0.5,
      cell: (row) => <span>N/A</span>,
    },
    {
      name: '',
      selector: '',
      cell: (row) => <AddToEstimateButton row={row} />,
      grow: 2,
    },
  ];

  const nameplateColumns: IDataTableColumn<any>[] = [
    {
      name: 'Asset',
      selector: 'asset_number',
      sortable: true,
    },
    {
      name: 'Manufacturer',
      selector: 'manufacturer',
      sortable: true,
    },
    {
      name: '',
      selector: '',
      cell: (row) => <AddToEstimateButton row={row} />,
      grow: 2,
    },
  ];

  useEffect(() => {
    fetchFormOptions();
  }, []);

  useEffect(() => {
    if (salesEstimate?.xoi_job_id) {
      fetchXoiNameplates();
    }

    let newFormValues = salesEstimate?.sales_estimate_assets?.map((sales_estimate_asset) => {
      return sales_estimate_asset.asset;
    });
    setFormValues(newFormValues);
  }, [salesEstimate]);

  let requiredFields = ['manufacturer_id', 'model_number'];

  useEffect(() => {
    if (Array.isArray(formValues)) {
      let missing = formValues.some((item) => requiredFields.some((field) => !item[field]));
      setMissingInfo(missing);
    }
  }, [formValues]);

  const handleXoiRefresh = () => {
    setPollingXoi(true);

    const interval = setInterval(() => {
      fetch(`/xoi_jobs/${salesEstimate?.xoi_job_id}/nameplates`)
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          const assetCount = data.assets.length;
          setXoiButtonText('Add 1 Asset(s) from XOi');
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }, 10000);
  };

  const updateFormValues = (index, key, value) => {
    let newFormValues = [...formValues];
    newFormValues[index] = { ...newFormValues[index], [key]: value };
    setFormValues(newFormValues);
  };

  const handleAddAsset = () => {
    let newFormValues = [...formValues];
    newFormValues.push({ asset_number: 'New Unit (Set after save)' });
    setFormValues(newFormValues);
  };

  const handleRemoveAsset = (index) => {
    let newFormValues = [...formValues];
    newFormValues.splice(index, 1);
    setFormValues(newFormValues);
  };

  const handleSubmit = () => {
    setSubmitting(true);

    axios
      .post(`sales_estimates/${salesEstimateId}/sales_estimate_assets`, { assets: formValues })
      .then((response) => {
        history.push(`/rooftop_automation/${salesEstimateId}/equipment`);
      })
      .catch((error) => {
        setSubmitting(false);
        console.error('Error posting data:', error);
      });
  };

  const handleUpdateAge = (index, value) => {
    let newFormValues = [...formValues];

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;

    let yearsAgo = Math.floor(value);
    let monthsAgo = Math.round((value - yearsAgo) * 12);

    let newVintageYear = currentYear - yearsAgo;
    let newVintageMonth = currentMonth - monthsAgo;

    if (newVintageMonth < 1) {
      newVintageMonth += 12;
      newVintageYear -= 1;
    }

    let monthToUse = newFormValues[index].vintage_month;

    // If the user enters a decimal value for the age, then set the vintage month.  Otherwise, leave the vintage month as is.
    if (value % 1 !== 0) {
      monthToUse = newVintageMonth;
    }

    newFormValues[index] = {
      ...newFormValues[index],
      age: value,
      vintage_year: newVintageYear,
      vintage_month: monthToUse,
    };
    setFormValues(newFormValues);
  };

  const handleUpdateVintage = (index, vintageYear, vintageMonth) => {
    let newFormValues = [...formValues];

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;

    let yearDifference = currentYear - vintageYear;
    let monthDifference = currentMonth - vintageMonth || 0;

    if (monthDifference < 0) {
      yearDifference -= 1;
      monthDifference += 12;
    }

    let age = yearDifference + monthDifference / 12;
    let roundedAge = Math.round(age * 4) / 4;

    newFormValues[index] = {
      ...newFormValues[index],
      vintage_year: vintageYear,
      vintage_month: vintageMonth,
      age: roundedAge,
    };
    setFormValues(newFormValues);
  };

  return (
    formOptions?.manufacturer_choices && (
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <h2 style={{ fontSize: '22px', marginBottom: 10, paddingTop: 5 }}>System Details</h2>
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="right">
            {loading && (
              <>
                <CircularProgress size={24} />
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>Waiting for tag data from XOi...</Typography>
              </>
            )}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleOpenModal} style={{ float: 'right' }}>
                {xoiButtonText}
              </Button>
            </Grid>
          </Grid>
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 500,
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <Typography variant="h6">{salesEstimate?.opportunity_name}</Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <Typography variant="h6">Nameplates</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isPollingXoi}
                  style={{ marginTop: '0px' }}
                  onClick={handleXoiRefresh}
                  endIcon={isPollingXoi ? <></> : <RefreshIcon />}
                >
                  {isPollingXoi ? 'Refreshing...' : 'Refresh'}
                </Button>
              </div>
              <DataTable
                title="Nameplates"
                columns={nameplateColumns}
                data={[]}
                striped
                dense
                highlightOnHover
                pointerOnHover
                noHeader
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '20px', width: '100%' }}
                onClick={() => window.open(salesEstimate?.xoi_job_link, '_blank')}
                endIcon={<OpenInNewIcon />}
              >
                Go To XOi
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: '20px', width: '100%' }}
                onClick={() => alert('Checking for nameplates...')}
              >
                Save Nameplates to Assets
              </Button>
            </div>
          </Modal>
        </Grid>

        {/* <div style={{ margin: '20px 0' }}>
          <SelectInput label="Estimate Type" value={'RTU'} choices={[{ label: 'Rooftop Units (RTU)', value: 'RTU' }]} />
        </div> */}
        <br />
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <DataTable
            title="Preexisting Rooftop Assets"
            columns={columns}
            data={preexistingRooftopAssets}
            striped
            dense
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Grid>
        <br />
        {formValues?.map((item, index) => (
          <Card style={{ marginBottom: 20, position: 'relative', overflow: 'visible' }} key={`field-${index}`}>
            <IconButton
              style={{
                position: 'absolute',
                right: -10,
                top: -10,
                color: 'white',
                backgroundColor: 'red',
                borderRadius: '50%',
                padding: '4px',
                zIndex: 999,
                boxShadow: '0px 2px 4px rgba(0,0,0,0.5)',
              }}
              onClick={() => {
                handleRemoveAsset(index);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <CardContent style={{ padding: '15px 30px 15px 15px' }}>
              <Grid key={item.id} container spacing={3}>
                <Grid item xs={12}>
                  <strong>Asset {index + 1}</strong>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    label="Asset Number"
                    value={formValues[index].asset_number}
                    valueChanged={(value) => updateFormValues(index, 'asset_number', value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={formOptions?.manufacturer_choices}
                    getOptionLabel={(option: any) => option.label}
                    sx={{ width: '100%' }}
                    value={formOptions?.manufacturer_choices?.find(
                      (option) => option.value === formValues[index].manufacturer_id,
                    )}
                    onChange={(event, newValue) => {
                      updateFormValues(index, 'manufacturer_id', newValue?.value || '');
                    }}
                    renderInput={(params) => <TextField {...params} label="Manufacturer" />}
                  />
                </Grid>
                <Grid item xs={7} md={4}>
                  <TextInput
                    label="Age"
                    shrinkLabel
                    value={formValues[index].age}
                    valueChanged={(value) => handleUpdateAge(index, value)}
                  />
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography style={{ marginTop: 20 }}>OR</Typography>
                </Grid>
                <Grid item xs={5} md={3}>
                  <TextInput
                    label="Vintage Year"
                    shrinkLabel
                    value={formValues[index].vintage_year}
                    valueChanged={(value) => handleUpdateVintage(index, value, formValues[index].vintage_month)}
                  />
                </Grid>
                <Grid item xs={5} md={3}>
                  <SelectInput
                    label="Vintage Month"
                    value={formValues[index].vintage_month}
                    choices={monthOptions}
                    valueChanged={(value) => handleUpdateVintage(index, formValues[index].vintage_year, value)}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextInput
                    label="Size"
                    value={formValues[index].size}
                    valueChanged={(value) => updateFormValues(index, 'size', value)}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextInput
                    label="Model Number"
                    value={formValues[index].model_number}
                    valueChanged={(value) => updateFormValues(index, 'model_number', value)}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextInput
                    label="Serial"
                    value={formValues[index].serial_number}
                    valueChanged={(value) => updateFormValues(index, 'serial_number', value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}

        <Grid item xs={12}>
          <Button variant="contained" onClick={handleAddAsset} type="button" color="secondary">
            Add Asset
          </Button>
        </Grid>

        <hr />
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting || missingInfo}>
            {isSubmitting ? 'Submitting assets...' : 'Submit Assets'}
          </Button>
        </Grid>
      </Grid>
    )
  );
};

export default AssetForm;
