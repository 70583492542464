import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import axios from '../../utils/axios.utils';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Executed By',
    selector: 'executed_by',
    sortable: true,
  },
  {
    name: 'Executed At',
    selector: 'executed_at',
    sortable: true,
  },
  {
    name: 'SQL Query',
    selector: 'query',
    sortable: true,
  },
  {
    name: 'Servman Response',
    selector: 'response',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
];

const ServmanSqlLogsList: React.FunctionComponent = () => {
  const history = useHistory();

  const [sqlLogsFetched, setSqlLogsFetched] = useState(false);
  const [sqlLogs, setSqlLogs] = useState<any>([]);

  const fetchCustomers = async () => {
    await axios.get('/servman_data/sql_logs').then((response: any) => {
      setSqlLogs(response.data.result);
    });
  };

  useEffect(() => {
    if (!sqlLogsFetched) {
      fetchCustomers();
      setSqlLogsFetched(true);
    }
  }, [sqlLogsFetched]);

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ marginTop: 5 }}>
              Servman SQL Logs
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title=""
              columns={columns}
              data={sqlLogs}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ServmanSqlLogsList;
