import React, { FC, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Edit from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save';
import Delete from '@mui/icons-material/Delete';

import TextInput from '../../form/components/TextInput';
import DetailSubItemsList from './DetailSubItemsList';

import { ProposalDetailBullet } from '../model';

import axios from '../../utils/axios.utils';

interface ProposalDetailBulletFormEditProps {
  proposalDetailBullet: ProposalDetailBullet;
  fetchProposalDetail: () => void;
}

const ProposalDetailBulletFormEdit: FC<ProposalDetailBulletFormEditProps> = (props) => {
  let { proposalDetailBullet, fetchProposalDetail } = props;

  let [formValues, setFormValues] = useState<ProposalDetailBullet>();
  let [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setFormValues(proposalDetailBullet);
  }, [proposalDetailBullet]);

  let handleSave = async () => {
    try {
      await axios.put(`/proposal_detail_bullets/${proposalDetailBullet.id}`, formValues);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save proposal detail bullet', error);
    }
  };

  return (
    <Grid container style={{ marginBottom: 10 }}>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        {isEditing ? (
          <Save style={{ color: 'blue' }} onClick={handleSave} />
        ) : (
          <Edit style={{ color: 'purple' }} onClick={() => setIsEditing(true)} />
        )}
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <Delete style={{ color: 'red' }} />
      </Grid>
      <Grid item xs={10} md={8}>
        <Grid container>
          <Grid item xs={12}>
            {isEditing ? (
              <TextInput
                label=""
                value={formValues?.description}
                valueChanged={(value) => setFormValues({ ...formValues, description: value })}
              />
            ) : (
              <p>{formValues?.description}</p>
            )}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10}>
            {!proposalDetailBullet?.category.includes('sub_') && (
              <DetailSubItemsList
                proposalDetailBullets={proposalDetailBullet?.sub_bullets}
                bulletableId={proposalDetailBullet?.id}
                category={`sub_${proposalDetailBullet?.description}`}
                fetchProposalDetail={fetchProposalDetail}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProposalDetailBulletFormEdit;
