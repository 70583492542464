import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

import SelectInput from '../../form/components/SelectInput';
import TextInput from '../../form/components/TextInput';
import axios from '../../utils/axios.utils';

import { InstallationCostRecord } from '../../estimateDetails/model';

interface InstallationCostModalProps {
  editModalOpen: boolean;
  setEditModalOpen: Dispatch<SetStateAction<boolean>>;
  salesEstimate: any;
  descriptionOptions?: any[];
  recordToEdit?: InstallationCostRecord;
}

const titleByPrimaryCategory = {
  material: 'Material',
  labor: 'Labor',
  subcontractor: 'Subcontractor',
  miscellaneous: 'Miscellaneous',
};

const unitLabelByPrimaryCategory = {
  material: 'Unit',
  labor: 'Hour',
  subcontractor: 'Unit',
  miscellaneous: 'Unit',
};

const InstallationCostModal: React.FC<InstallationCostModalProps> = (props) => {
  const { editModalOpen, setEditModalOpen, salesEstimate, descriptionOptions = [], recordToEdit } = props;

  let [formValues, setFormValues] = useState<InstallationCostRecord>({});
  let [materialCategory, setMaterialCategory] = useState('');
  let [totalCost, setTotalCost] = useState(0);
  let [initialHours, setInitialHours] = useState('0');

  const rateWithOvertime = () => {
    return formValues?.overtime ? parseFloat(formValues?.cost_per_unit) * 1.5 : formValues?.cost_per_unit;
  };

  // Update total cost independently to avoid circular re-rendering.
  useEffect(() => {
    const total = parseFloat(formValues?.cost_per_unit) * parseFloat(formValues?.job_units || '0');
    const overtimeFactor = formValues?.overtime ? 1.5 : 1;
    setTotalCost(total * overtimeFactor);
  }, [formValues?.cost_per_unit, formValues?.job_units, formValues?.overtime]);

  const handleSubmit = async () => {
    await axios.put(`/installation_cost_records/${recordToEdit.id}`, {
      installation_cost_record: formValues,
    });

    window.location.reload();
  };

  useEffect(() => {
    setFormValues({
      ...recordToEdit,
      overtime: recordToEdit?.overtime ?? false,
    });
    setInitialHours(recordToEdit?.job_units);
  }, [recordToEdit]);

  return (
    <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
      <Grid container>
        <Grid item xs={12}>
          <DialogTitle>Edit {titleByPrimaryCategory[recordToEdit?.primary_category]} Cost</DialogTitle>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: -5,
            display: 'flex',
            justifyContent: 'flex-center',
            paddingLeft: '10%',
            paddingRight: '10%',
          }}
        >
          <DialogContent style={{ width: 400 }}>
            <SelectInput
              label="Asset Number"
              value={formValues?.sales_estimate_asset_id}
              valueChanged={(value) => setFormValues({ ...formValues, sales_estimate_asset_id: value })}
              choices={salesEstimate.sales_estimate_assets?.flatMap((asset) => ({
                label: asset.asset_number,
                value: asset.id,
              }))}
            />
            <br />
            <SelectInput
              label="Description"
              value={formValues?.installation_cost_id}
              valueChanged={(value) => {
                setFormValues({ ...formValues, installation_cost_id: value });
                setMaterialCategory(descriptionOptions.filter((option) => option.value === value)[0]?.label);
              }}
              choices={descriptionOptions}
            />
            <br />
            {materialCategory === 'Other' && (
              <>
                <TextInput
                  label="Other Description"
                  value={formValues?.description_override}
                  shrinkLabel
                  valueChanged={(value) => setFormValues({ ...formValues, description_override: value })}
                />
                <br />
              </>
            )}
            <TextInput
              label={`Cost per ${unitLabelByPrimaryCategory[recordToEdit?.primary_category]}`}
              value={formValues?.cost_per_unit}
              shrinkLabel
              currency
              valueChanged={(value) => setFormValues({ ...formValues, cost_per_unit: value })}
            />
            <br />
            <TextInput
              label={`${unitLabelByPrimaryCategory[recordToEdit?.primary_category]}s`}
              value={formValues?.job_units}
              shrinkLabel
              valueChanged={(value) => setFormValues({ ...formValues, job_units: value })}
            />
            <br />
            {recordToEdit?.primary_category === 'labor' && (
              <Grid container style={{ display: 'inline' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues?.custom_hours}
                      onChange={(e) => setFormValues({ ...formValues, custom_hours: e.target.checked })}
                    />
                  }
                  label="Custom Hours"
                  style={{ marginTop: -10 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues?.overtime}
                      onChange={(e) => setFormValues({ ...formValues, overtime: e.target.checked })}
                    />
                  }
                  label="Overtime"
                  style={{ marginTop: -10, marginLeft: 10 }}
                />
                {formValues?.overtime && (
                  <Typography style={{ marginTop: 10 }}>Rate with Overtime: ${rateWithOvertime()}</Typography>
                )}
              </Grid>
            )}
            <Typography style={{ marginTop: 10 }}>Total Cost: ${totalCost?.toFixed(2) || ''}</Typography>
          </DialogContent>
        </Grid>
        <Grid item xs={12}>
          {formValues?.job_units !== initialHours && (
            <p style={{ color: 'red' }}>Does this Labor Cost need to be marked as custom hours?</p>
          )}
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DialogActions>
            <Button onClick={() => setEditModalOpen(false)}>Cancel</Button>
            <Button type="submit" onClick={handleSubmit}>
              Update
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default InstallationCostModal;
