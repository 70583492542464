/* eslint-disable react-hooks/exhaustive-deps, no-alert, no-nested-ternary, react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AlertBubbleIcon } from '@shopify/polaris-icons';
import { Navigation } from '@shopify/polaris';

import axios from '../../utils/axios.utils';

const Sidebar: React.FC = () => {
  const location = useLocation();

  let [rebatesToReviewCount, setRebatesToReviewCount] = useState<number>(0);

  const fetchRebateRecordsCount = async () => {
    try {
      const response: any = await axios.get('/rebate_records/new');
      setRebatesToReviewCount(response.data.rebates_to_review_count);
    } catch (error) {
      console.error('Error fetching rebate records count:', error);
    }
  };

  const reviewRebatesLabel = `Rebates to Review ${rebatesToReviewCount > 0 ? `(${rebatesToReviewCount})` : ''}`;

  useEffect(() => {
    fetchRebateRecordsCount();
  }, []);

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            label: 'Sales Estimates',
            url: '/rooftop_automation/customers/search',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/rooftop_automation/customers/new',
                disabled: false,
                label: 'New Customer',
              },
              {
                url: '/rooftop_automation/customers/search',
                disabled: false,
                label: 'Existing Customer',
              },
              {
                url: '/rooftop_automation/recent',
                disabled: false,
                label: 'Recent Estimates',
              },
              {
                url: '/rooftop_automation/rebate_records',
                disabled: false,
                label: reviewRebatesLabel,
              },
            ],
          },
          {
            label: 'Estimate Settings',
            url: '/admin',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/admin/equipment_costs',
                disabled: false,
                label: 'Replacement Equipment',
              },
              {
                url: '/admin/system_accessories',
                disabled: false,
                label: 'System Accessories',
              },
              {
                url: '/admin/installation_costs',
                disabled: false,
                label: 'Installation Costs',
              },
              {
                url: '/admin/rebates',
                disabled: false,
                label: 'Rebate Options',
              },
              {
                url: '/xoi_jobs',
                disabled: false,
                label: 'XOi Job Lookup',
              },
            ],
          },
          {
            label: 'ServMan Data',
            url: '/admin',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/admin/servman_sql_logs',
                disabled: false,
                label: 'SQL Logs',
              },
              {
                url: '/admin/servman_customers',
                disabled: false,
                label: 'Customers',
              },
              {
                url: '/admin/servman_assets',
                disabled: false,
                label: 'Assets',
              },
              {
                url: '/admin/servman_equipment_types',
                disabled: false,
                label: 'Equipment Types',
              },
              {
                url: '/admin/servman_client_types',
                disabled: false,
                label: 'Client Types',
              },
              {
                url: '/admin/servman_contact_types',
                disabled: false,
                label: 'Contact Types',
              },
              {
                url: '/admin/servman_phone_types',
                disabled: false,
                label: 'Phone Types',
              },
              {
                url: '/admin/servman_manufacturers',
                disabled: false,
                label: 'Manufacturers',
              },
              {
                url: '/admin/zip_codes',
                disabled: false,
                label: 'Zip Codes',
              },
            ],
          },
          {
            label: 'Admin',
            url: '/admin',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/admin/user_management',
                disabled: false,
                label: 'User Management',
              },
            ],
          },
        ]}
      />
    </Navigation>
  );
};

export default Sidebar;
