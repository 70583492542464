import React from 'react';
import { Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import LoginForm from '../components/LoginForm';

const LoginPage: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push('/rooftop_automation/recent');
    }
  }, [isAuthenticated]);

  return (
    <div style={{ marginTop: 100, marginLeft: 20, marginRight: 20 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ textAlign: 'center', marginLeft: -100 }}>
          <img
            style={{ height: 40 }}
            alt="logo"
            src="https://www.tudi.com/wp-content/themes/boxpress/assets/img/dist/branding/tudi-mechanical-logo@2x.png"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LoginForm />
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
