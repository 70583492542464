import React, { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Grid } from '@mui/material';

import Save from '@mui/icons-material/Save';
import Edit from '@mui/icons-material/Edit';

import TextInput from '../../form/components/TextInput';

import { ProposalDetail } from '../model';

import axios from '../../utils/axios.utils';

interface ProposalFormFieldAreaProps {
  proposalDetail: ProposalDetail;
  setProposalDetail: Dispatch<SetStateAction<ProposalDetail>>;
  label: string;
  fieldName: string;
}

const ProposalFormFieldArea: FC<ProposalFormFieldAreaProps> = (props) => {
  let { proposalDetail, setProposalDetail, label, fieldName } = props;

  let [value, setValue] = useState<string>('');
  let [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (proposalDetail) setValue(proposalDetail[fieldName]);
  }, [proposalDetail]);

  const handleProposalUpdate = async () => {
    try {
      let response: any = await axios.put(`/proposal_details/${proposalDetail?.id}`, {
        proposal_detail: {
          [fieldName]: value,
        },
      });

      setProposalDetail(response.data.result);
    } catch (error) {
      console.error('Failed to update proposal detail', error);
    }
  };

  const handleSaveClicked = () => {
    handleProposalUpdate();
    setEditing(false);
  };

  return (
    <>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {editing ? (
          <Save onClick={handleSaveClicked} style={{ color: 'blue', cursor: 'pointer' }} />
        ) : (
          <Edit onClick={() => setEditing(true)} style={{ color: 'purple', cursor: 'pointer' }} />
        )}
      </Grid>
      <Grid item xs={11} style={{ height: 75, marginBottom: 15 }}>
        {editing ? (
          <TextInput
            label={label}
            value={value}
            valueChanged={(newValue) => setValue(newValue)}
            multiline
            shrinkLabel
            rows={2}
          />
        ) : (
          <p>{value}</p>
        )}
      </Grid>
    </>
  );
};

export default ProposalFormFieldArea;
