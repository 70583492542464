import Axios from 'axios';
import { API_ENDPOINT } from './env';

const API = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 120000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `${sessionStorage?.getItem('userEmail') || 'admin@tudi.com'}`,
  },
});

export default API;
