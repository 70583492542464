// columns.tsx
import React from 'react';
import { Delete } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IDataTableColumn } from 'react-data-table-component';
import { SalesEstimate } from '../model';

const formatNumber = (number) => (number != null ? parseFloat(number).toFixed(2) : '');

interface RowData {
  id: string;
  name: string;
  customer_name: string;
  created_at: string;
  total_costs: string;
  total_rebates: string;
  sales_tax: string;
  net_cost: string;
  created_by: string;
}

export const salesEstimateColumns = (
  handleDelete: (row: SalesEstimate) => void,
  handleDuplicate: (row: SalesEstimate) => void,
): IDataTableColumn<RowData>[] => [
  {
    name: 'Estimate Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Quote Number',
    selector: 'quote_number',
    sortable: true,
  },
  {
    name: 'Customer',
    selector: 'customer_name',
    sortable: true,
  },
  {
    name: 'Sale Price',
    selector: 'sale_price',
    sortable: true,
  },
  {
    name: 'Asset Count',
    selector: 'total_assets',
    sortable: true,
  },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: true,
  },
  {
    name: 'Created By',
    selector: 'created_by',
    sortable: true,
  },
  {
    name: 'Actions',
    selector: 'delete',
    cell: (row) => (
      <>
        <button
          style={{
            cursor: 'pointer',
            display: 'inline-block',
            padding: '5px',
            border: 'none',
            background: 'none',
          }}
          onClick={() => handleDuplicate(row)}
          aria-label="Delete"
        >
          <ContentCopyIcon />
        </button>
        <button
          style={{
            cursor: 'pointer',
            display: 'inline-block',
            padding: '5px',
            border: 'none',
            background: 'none',
          }}
          onClick={() => handleDelete(row)}
          aria-label="Delete"
        >
          <Delete />
        </button>
      </>
    ),
  },
];
