import React, { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';

import Grid from '@mui/material/Grid';
import Save from '@mui/icons-material/Save';
import Delete from '@mui/icons-material/Delete';
import Cancel from '@mui/icons-material/Cancel';

import TextInput from '../../form/components/TextInput';

import { ProposalDetailBullet } from '../model';

import axios from '../../utils/axios.utils';

interface ProposalDetailBulletFormNewProps {
  proposalDetailBullet: ProposalDetailBullet;
  setNewBulletOpen: Dispatch<SetStateAction<boolean>>;
  fetchProposalDetail: () => void;
}

const ProposalDetailBulletFormNew: FC<ProposalDetailBulletFormNewProps> = (props) => {
  let { proposalDetailBullet, setNewBulletOpen, fetchProposalDetail } = props;

  let [formValues, setFormValues] = useState<ProposalDetailBullet>();

  useEffect(() => {
    setFormValues({
      ...proposalDetailBullet,
    });
  }, [proposalDetailBullet]);

  let handleSave = async () => {
    try {
      await axios.post('/proposal_detail_bullets', formValues);
      fetchProposalDetail();
      setNewBulletOpen(false);
    } catch (error) {
      console.error('Failed to save proposal detail bullet', error);
    }
  };

  return (
    <Grid container style={{ marginBottom: 10 }}>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Save style={{ color: 'blue' }} onClick={handleSave} />
      </Grid>
      <Grid
        item
        xs={1}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClick={() => setNewBulletOpen(false)}
      >
        <Cancel style={{ color: 'gray' }} />
      </Grid>
      <Grid item xs={10} md={8}>
        <TextInput
          label=""
          value={formValues?.description}
          valueChanged={(value) => setFormValues({ ...formValues, description: value })}
        />
      </Grid>
    </Grid>
  );
};

export default ProposalDetailBulletFormNew;
