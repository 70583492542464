import React, { FC, useState } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';

import { PDFDownloadLink } from '@react-pdf/renderer';
import EstimatePDF from '../../estimateReports/pages/EstimatePDF';

import TextInput from '../../form/components/TextInput';
import DetailItemsList from './DetailItemsList';
import ProposalFormFieldArea from './ProposalFormFieldArea';

import { SalesEstimate, ProposalDetail, ProposalDetailBullet } from '../model';

import axios from '../../utils/axios.utils';

interface ProposalDetailAccordionProps {
  salesEstimate: SalesEstimate;
  proposalDetailId: string;
}

const ProposalDetailAccordion: FC<ProposalDetailAccordionProps> = (props) => {
  let { salesEstimate, proposalDetailId } = props;

  let [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);
  let [proposalDetail, setProposalDetail] = useState<ProposalDetail>();
  let [paragraphFormValues, setParagraphFormValues] = useState<ProposalDetail>();
  let [scopeOfWorkItems, setScopeOfWorkItems] = useState<ProposalDetailBullet[]>([]);
  let [warrantyItems, setWarrantyItems] = useState<ProposalDetailBullet[]>([]);
  let [exclusionItems, setExclusionItems] = useState<ProposalDetailBullet[]>([]);

  const fetchProposalDetail = async () => {
    try {
      let response: any = await axios.get(`/proposal_details/${proposalDetailId}`);
      setParagraphFormValues(response.data.result);
      setProposalDetail(response.data.result);
      setScopeOfWorkItems(response.data.result.scope_of_work_items);
      setWarrantyItems(response.data.result.warranty_items);
      setExclusionItems(response.data.result.exclusion_items);
    } catch (error) {
      console.error('Failed to fetch proposal detail', error);
    }
  };

  const handleAccordionExpanded = () => {
    if (!accordionExpanded && proposalDetailId) {
      fetchProposalDetail();
    }
    setAccordionExpanded(!accordionExpanded);
  };

  const itemLists = [
    {
      items: scopeOfWorkItems,
      setItems: setScopeOfWorkItems,
      category: 'Scope of Work',
    },
    {
      items: warrantyItems,
      setItems: setWarrantyItems,
      category: 'Warranty',
    },
    {
      items: exclusionItems,
      setItems: setExclusionItems,
      category: 'Exclusions',
    },
  ];

  return (
    <Accordion expanded={accordionExpanded} onChange={handleAccordionExpanded} style={{ backgroundColor: '#dcf5e6' }}>
      <AccordionSummary>
        <Typography>Proposal Detail</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="div">
              Greeting
            </Typography>
          </Grid>
          <ProposalFormFieldArea
            label="Greeting Paragraph"
            fieldName="greeting_paragraph"
            proposalDetail={proposalDetail}
            setProposalDetail={setProposalDetail}
          />
          {itemLists.map((itemList) => (
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <Card style={{ padding: '2%' }}>
                <DetailItemsList
                  proposalDetailBullets={itemList.items}
                  bulletableId={proposalDetailId}
                  category={itemList.category}
                  fetchProposalDetail={fetchProposalDetail}
                />
              </Card>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography variant="h5" component="div">
              Closing
            </Typography>
          </Grid>
          <ProposalFormFieldArea
            label="Terms"
            proposalDetail={proposalDetail}
            fieldName="terms"
            setProposalDetail={setProposalDetail}
          />
          <ProposalFormFieldArea
            label="Closing Paragraph"
            fieldName="closing_paragraph"
            proposalDetail={proposalDetail}
            setProposalDetail={setProposalDetail}
          />
          {/* <Grid item xs={12}>
            <Typography variant="h5" component="div">
              Signature
            </Typography>
          </Grid>
          <ProposalFormFieldArea
            label="Signature"
            proposalDetail={proposalDetail}
            fieldName="signature"
            setProposalDetail={setProposalDetail}
          />
          <ProposalFormFieldArea
            label="Title"
            proposalDetail={proposalDetail}
            fieldName="title"
            setProposalDetail={setProposalDetail}
          /> */}
          <Grid item xs={12}>
            <PDFDownloadLink
              document={<EstimatePDF salesEstimate={salesEstimate} proposalDetail={proposalDetail} />}
              fileName={`${salesEstimate?.name}.pdf`}
              style={{ marginLeft: 10 }}
            >
              {({ blob, url, loading, error }) => (
                <Button variant="contained" color="secondary" disableElevation>
                  {loading ? 'Loading document...' : 'Download PDF'}
                </Button>
              )}
            </PDFDownloadLink>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProposalDetailAccordion;
