import React, { FunctionComponent, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Link, TextField, Box } from '@mui/material';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { SalesEstimateCustomerFormValues, SalesEstimateContactFormValues, SalesEstimateSiteFormValues } from '../model';
import axios from '../../utils/axios.utils';
import { EstimateCustomersContext } from '../contexts/EstimateCustomersContext';
import { SalesEstimate } from '../../estimateDetails/model';

const NewCustomerForm = () => {
  const history = useHistory();

  const [customerFormValues, setCustomerFormValues] = useState<SalesEstimateCustomerFormValues>({});
  const [contactFormValues, setContactFormValues] = useState<SalesEstimateContactFormValues>({});
  const [salesEstimateFormValues, setSalesEstimateFormValues] = useState<SalesEstimate>({});
  const [siteFormValues, setSiteFormValues] = useState<SalesEstimateSiteFormValues>({});
  const [formOptionsFetched, setFormOptionsFetched] = useState<boolean>(false);

  const { customerFormOptions, setCustomerFormOptions } = useContext(EstimateCustomersContext);

  const fetchFormOptions = async () => {
    const response = await axios.get('/customers/0/form_options');
    setCustomerFormOptions(response.data);
    setFormOptionsFetched(true);
  };

  useEffect(() => {
    if (!formOptionsFetched) {
      fetchFormOptions();
    }
  }, []);

  const createCustomer = async () => {
    try {
      // First, create the customer
      let response: any = await axios.post('/customers', { customer: customerFormValues });
      console.log('Customer response', response);
      let customerId = response.data.id;

      // Then the contact
      let contactFormValuesWithCustomer = { ...contactFormValues, customer_id: customerId };
      response = await axios.post('/contacts', { contact: contactFormValuesWithCustomer });
      console.log('Contact response', response);
      let contactId = response.data.id;

      // Then the site
      let siteFormValuesWithContact = { ...siteFormValues, contact_id: contactId, customer_id: customerId };
      response = await axios.post('/sites', { site: siteFormValuesWithContact });
      console.log('Site response', response);
      let siteId = response.data.id;

      // Finally, create the sales estimate
      await axios
        .post('/sales_estimates', {
          sales_estimate: {
            ...salesEstimateFormValues,
            customer_id: customerId,
            site_id: siteId,
            contact_id: contactId,
          },
        })
        .then((response: any) => {
          const newSalesEstimateId = response.data.id;
          history.push(`/rooftop_automation/${newSalesEstimateId}/system`);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Estimate Details</h2>
      <TextInput
        label="Estimate Name"
        value={salesEstimateFormValues.name}
        shrinkLabel
        valueChanged={(value: string) => setSalesEstimateFormValues({ ...salesEstimateFormValues, name: value })}
      />
      <br />
      <TextInput
        label="Quote Number"
        value={salesEstimateFormValues.quote_number}
        shrinkLabel
        valueChanged={(value: string) =>
          setSalesEstimateFormValues({ ...salesEstimateFormValues, quote_number: value })
        }
      />
      <br />
      <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Customer Details</h2>
      <TextInput
        label="Customer Name"
        value={customerFormValues.name}
        shrinkLabel
        valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, name: value })}
      />
      <br />
      <SelectInput
        label="Customer Type"
        choices={customerFormOptions?.customer_type_choices}
        value={customerFormValues.customer_type_id}
        valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, customer_type_id: value })}
      />
      <br />
      <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Contact Info</h2>
      <TextInput
        label="Contact First Name"
        value={contactFormValues.first_name}
        shrinkLabel
        valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, first_name: value })}
      />
      <br />
      <TextInput
        label="Contact Last Name"
        value={contactFormValues.last_name}
        shrinkLabel
        valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, last_name: value })}
      />
      <br />
      <SelectInput
        label="Contact Type"
        choices={customerFormOptions?.contact_type_choices}
        value={contactFormValues.contact_type_id}
        valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, contact_type_id: value })}
      />
      <br />
      <TextInput
        label="Contact Email"
        value={customerFormValues.email}
        shrinkLabel
        valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, email: value })}
      />
      <br />
      <TextInput
        label="Phone 1"
        value={customerFormValues.phone1}
        shrinkLabel
        valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, phone1: value })}
      />
      <br />
      {/* Commenting out the Phone Type for now because there isn't a column on the customer table for it */}
      {/* <TextInput
        label="Phone 1 Type"
        value={customerFormValues.phone1}
        shrinkLabel
        valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, phone1: value })}
      />
      <br /> */}
      <TextInput
        label="Phone 2"
        value={customerFormValues.phone2}
        shrinkLabel
        valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, phone2: value })}
      />
      <br />
      {/* <TextInput
        label="Phone 2 Type"
        value={customerFormValues.phone1}
        shrinkLabel
        valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, phone1: value })}
      /> */}
      <br />
      <hr />
      <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Customer Address</h2>
      <TextInput
        label="Site Name"
        value={siteFormValues.name}
        shrinkLabel
        valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, name: value })}
      />
      <br />
      <TextInput
        label="Address 1"
        value={siteFormValues.address1}
        shrinkLabel
        valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, address1: value })}
      />
      <br />
      <TextInput
        label="Address 2"
        value={siteFormValues.address2}
        shrinkLabel
        valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, address2: value })}
      />
      <br />
      <TextInput
        label="City"
        value={siteFormValues.city}
        shrinkLabel
        valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, city: value })}
      />
      <br />
      <TextInput
        label="State"
        value={siteFormValues.state}
        shrinkLabel
        valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, state: value })}
      />
      <br />
      <TextInput
        label="Zip"
        value={siteFormValues.zip}
        shrinkLabel
        valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, zip: value })}
      />
      <br />
      <Button variant="contained" color="primary" onClick={createCustomer}>
        Create Customer
      </Button>
    </>
  );
};

export default NewCustomerForm;
