import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { Edit, Save } from '@mui/icons-material';

import { SalesEstimateKeyDetails, SalesEstimate } from '../model';

import KeyDetailsEdit from './KeyDetailsEdit';
import KeyDetailsView from './KeyDetailsView';

import axios from '../../utils/axios.utils';

interface KeyDetailsCardProps {
  keyDetails: SalesEstimateKeyDetails;
  salesEstimate: SalesEstimate;
  setSalesEstimate: Dispatch<SetStateAction<SalesEstimate>>;
}

const KeyDetailsCard: React.FC<KeyDetailsCardProps> = (props) => {
  let { keyDetails, salesEstimate, setSalesEstimate } = props;

  let [editorOpen, setEditorOpen] = useState(false);
  let [formValues, setFormValues] = useState<SalesEstimateKeyDetails>(keyDetails);

  const handleSaveClicked = async () => {
    await axios
      .put(`sales_estimates/0/sales_estimate_key_details/${salesEstimate.id}`, {
        sales_estimate_key_details: formValues,
      })
      .then((response: any) => {
        let updatedSalesEstimate = { ...salesEstimate };
        updatedSalesEstimate.sales_estimate_key_details = response.data.result;
        setSalesEstimate(updatedSalesEstimate);
        setEditorOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setFormValues(keyDetails);
  }, [keyDetails]);

  return (
    <Card style={{ padding: '2%' }}>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1" component="p">
            Key Details
          </Typography>
          {editorOpen ? <Save onClick={handleSaveClicked} /> : <Edit onClick={() => setEditorOpen(true)} />}
        </Grid>
        <Grid item xs={12}>
          {editorOpen ? (
            <KeyDetailsEdit keyDetails={keyDetails} formValues={formValues} setFormValues={setFormValues} />
          ) : (
            <KeyDetailsView keyDetails={keyDetails} />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default KeyDetailsCard;
