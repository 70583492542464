import React, { FC, useState } from 'react';
import { Grid, Button } from '@mui/material';
import InformationField from '../../form/components/InformationField';

import { SalesEstimateAsset } from '../../estimateDetails/model';

interface ExpandedSpecifxDetailsProps {
  salesEstimateAsset: SalesEstimateAsset;
}

const ExpandedSpecifxDetails: FC<ExpandedSpecifxDetailsProps> = (props) => {
  const [expanded, setExpanded] = useState(false);

  let { salesEstimateAsset } = props;

  const keyValues = salesEstimateAsset?.specifx_key_values?.fields || [];
  const initialKeyValues = keyValues.slice(0, 8);
  const remainingKeyValues = keyValues.slice(8);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="expanded-row" style={{ width: '100%' }}>
      <Grid container spacing={3}>
        {initialKeyValues.map((key_value, index) => (
          <Grid item xs={4} key={index}>
            <InformationField label={key_value.label} value={key_value.value || 'None'} />
          </Grid>
        ))}
        {expanded &&
          remainingKeyValues.map((key_value, index) => (
            <Grid item xs={4} key={index + 8}>
              <InformationField label={key_value.label} value={key_value.value || 'None'} />
            </Grid>
          ))}
      </Grid>
      {remainingKeyValues.length > 0 && (
        <Button variant="contained" color="primary" onClick={toggleExpanded} style={{ marginTop: '10px' }}>
          {expanded ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </div>
  );
};

export default ExpandedSpecifxDetails;
