import React, { FC, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import DataTable from '../../dataTable';

import { RebateRecord } from '../model';

import axios from '../../utils/axios.utils';

let rebateRecordsColumns = [
  { name: 'Sales Estimate', selector: 'sales_estimate_name', sortable: true },
  { name: 'Name', selector: 'name', sortable: true },
  { name: 'Last Updated', selector: 'updated_at', sortable: true },
  { name: 'Stage', selector: 'stage', sortable: true },
  {
    name: 'Actions',
    cell: (row: any) => (
      <a href={`/rebate_records/${row.id}`}>
        <Button variant="contained" color="primary">
          Review
        </Button>
      </a>
    ),
  },
];

const RebateRecordsListPage: React.FC = () => {
  let [rebateRecords, setRebateRecords] = useState<RebateRecord[]>([]);

  let fetchRebateRecords = async () => {
    let response: any = await axios.get('/rebate_records');
    setRebateRecords(response.data.result);
  };

  useEffect(() => {
    fetchRebateRecords();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DataTable columns={rebateRecordsColumns} data={rebateRecords} title="Rebates to Review" />
      </Grid>
    </Grid>
  );
};

export default RebateRecordsListPage;
