import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { SystemAccessoriesContext } from '../contexts/SystemAccessoriesContext';
import DataTable from '../../dataTable/DataTable/DataTable';
import SystemAccessoryFilterBar from '../components/SystemAccessoryFilterBar';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Asset Category',
    selector: 'asset_category',
    sortable: true,
    cell: (row) => <p>Rooftop Units</p>,
  },
  {
    name: 'Cost',
    selector: 'formatted_cost',
    sortable: true,
  },
  {
    name: 'Cost UOM',
    selector: 'cost_uom',
    sortable: true,
  },
  {
    name: 'Actions',
    sortable: true,
    selector: 'manual_link',
    cell: (row) => (
      <Button variant="contained" color="primary" onClick={() => window.open(row?.submittal_url)}>
        View Submittal
      </Button>
    ),
  },
];

const SystemAccessoriesList: React.FunctionComponent = () => {
  const history = useHistory();

  const [systemAccessoryFetched, setSystemAccessoryFetched] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState({});
  const { fetchSystemAccessories, systemAccessories, systemAccessoriesLoading } =
    React.useContext(SystemAccessoriesContext);

  React.useEffect(() => {
    if (!systemAccessoryFetched) {
      fetchSystemAccessories();
      setSystemAccessoryFetched(true);
    }
  }, []);

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ marginTop: 5 }}>
              System Accessories
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push('/admin/system_accessories/new')}>
              New System Accessory +
            </Button>
          </Grid>
        </Grid>
        <br />
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            {/* <SystemAccessoryFilterBar filterValues={filterValues} setFilterValues={setFilterValues} /> */}
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title="System Accessories"
              columns={columns}
              data={systemAccessories}
              onRowClicked={(row) => history.push(`/admin/system_accessories/${row.id}/edit`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default SystemAccessoriesList;
