import React, { FC, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Autocomplete, Button, Grid, TextField, Checkbox, FormControlLabel } from '@mui/material';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { EstimateCustomersContext } from '../contexts/EstimateCustomersContext';
import axios from '../../utils/axios.utils';
import { SelectOption } from '../../form/model';
import { SalesEstimateCustomerFormValues, SalesEstimateContactFormValues, SalesEstimateSiteFormValues } from '../model';
import { SalesEstimate } from '../../estimateDetails/model';

interface ExistingCustomerFormProps {
  salesEstimate: SalesEstimate;
}

const ExistingCustomerForm: FC<ExistingCustomerFormProps> = (props) => {
  const { salesEstimate } = props;
  const history = useHistory();

  const { fetchSites, siteChoices, customerFormOptions, fetchCustomerFormOptions } =
    useContext(EstimateCustomersContext);

  const [formErrors, setFormErrors] = useState<{ [key: string]: string[] }>({});
  const [generalErrorMessage, setGeneralErrorMessage] = useState<string | null>(null);
  const [customerFormValues, setCustomerFormValues] = useState<SalesEstimateCustomerFormValues>({});
  const [contactFormValues, setContactFormValues] = useState<SalesEstimateContactFormValues>({});
  const [siteFormValues, setSiteFormValues] = useState<SalesEstimateSiteFormValues>({});
  const [salesEstimateFormValues, setSalesEstimateFormValues] = useState<SalesEstimate>(salesEstimate);

  const [providerChoices, setProviderChoices] = useState<SelectOption[]>([]);
  const [siteId, setSiteId] = useState<string>(salesEstimate?.site_id);
  const [contactId, setContactId] = useState<number>();

  const fetchCustomer = async (customerId: string) => {
    const response: any = await axios.get(`/customers/${customerId}`);
    setCustomerFormValues(response.data.result);
  };

  const fetchSite = async (siteId: string) => {
    const response = await axios.get<string, any>(`/sites/${siteId}`);
    setSiteFormValues(response?.data?.result);
    const customerId = response?.data?.customer?.id;
    fetchCustomer(customerId);
    fetchCustomerFormOptions(customerId);
  };

  const fetchContact = async (contactId: number) => {
    const response: any = await axios.get(`/contacts/${contactId}`);
    setContactFormValues(response?.data?.result);
  };

  const fetchUtilityChoices = async () => {
    try {
      await axios.get(`zip_codes/${siteFormValues.zip.slice(0, 5)}`).then((response: any) => {
        setProviderChoices(response.data.result.utility_providers);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // TODO: Figure out why this renders four times on page load
  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    if (siteId) {
      fetchSite(siteId);
    }
  }, [siteId]);

  useEffect(() => {
    const firstContact = customerFormOptions?.contact_choices[0];
    if (firstContact) {
      setContactId(parseInt(firstContact.value));
    }
  }, [customerFormOptions]);

  useEffect(() => {
    if (contactId === 0) {
      // Clear form values if a "new contact" is selected
      const resetFormValues = Object.keys(contactFormValues).reduce((acc, key) => {
        acc[key] = '';
        return acc;
      }, {});
      setContactFormValues(resetFormValues);
    } else {
      fetchContact(contactId);
    }
  }, [contactId]);

  useEffect(() => {
    const zip = siteFormValues.zip;
    if (zip?.length >= 5) {
      fetchUtilityChoices();
    }
  }, [siteFormValues.zip]);

  const filterDuplicates = (choices) => {
    const uniqueLabels = new Set();
    return choices.filter((choice) => {
      if (uniqueLabels.has(choice.label)) {
        return false;
      }
      uniqueLabels.add(choice.label);
      return true;
    });
  };

  const formatErrors = (errors: { [key: string]: string[] }): string => {
    const formattedMessages = Object.entries(errors).map(([field, messages]) => {
      const fieldName = field.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
      return `- ${fieldName} ${messages.join(', ')}`;
    });
    return `Unable to save form for the following reasons:\n${formattedMessages.join('\n')}`;
  };

  const handleUpdate = async () => {
    try {
      // Clear previous errors
      setFormErrors({});

      // First, update the customer
      await axios.put(`/customers/${customerFormValues?.id}`, { customer: customerFormValues });

      // Then the contact
      let contactIdForUpdate = contactId;
      let contactFormValuesWithCustomerId = {
        ...contactFormValues,
        customer_id: customerFormValues?.id,
        primary: contactFormValues?.primary?.toString(),
      };

      // Zero corresponds to creating a new contact
      if (contactId === 0) {
        const response: any = await axios.post('/contacts', { contact: contactFormValuesWithCustomerId });
        contactIdForUpdate = response.data.id;
        setContactId(contactIdForUpdate);
      } else {
        await axios.put(`/contacts/${contactId}`, { contact: contactFormValuesWithCustomerId });
      }

      // Then the site
      const siteFormValuesWithContact = { ...siteFormValues, contact_id: contactIdForUpdate };
      await axios.put(`/sites/${siteId}`, { site: siteFormValuesWithContact });

      // Finally, create or update the sales estimate
      if (salesEstimate?.id) {
        await axios.put(`/sales_estimates/${salesEstimate?.id}`, {
          sales_estimate: {
            ...salesEstimateFormValues,
            customer_id: customerFormValues?.id,
            site_id: siteId,
            contact_id: contactIdForUpdate,
          },
        });
        history.push(`/rooftop_automation/${salesEstimate?.id}/system`);
      } else {
        const response: any = await axios.post('/sales_estimates', {
          sales_estimate: {
            ...salesEstimateFormValues,
            customer_id: customerFormValues?.id,
            site_id: siteId,
            contact_id: contactIdForUpdate,
          },
        });
        const newSalesEstimateId = response.data.id;
        history.push(`/rooftop_automation/${newSalesEstimateId}/system`);
      }
    } catch (error: any) {
      if (error.response?.data) {
        const formattedMessage = formatErrors(error.response.data);
        setGeneralErrorMessage(formattedMessage);
        setFormErrors(error.response.data);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <>
      {salesEstimate?.id ? (
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <TextInput
              label="Site Name"
              value={siteChoices.find((option) => option.value === salesEstimate?.site_id)?.label}
              shrinkLabel
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <h2 style={{ fontSize: '18px', marginBottom: 20, marginTop: -15 }}>Select A Site</h2>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={filterDuplicates(siteChoices)}
              getOptionLabel={(option) => option.label}
              sx={{ width: '100%' }}
              value={siteChoices.find((option) => option.value === siteId)}
              onChange={(event, newValue) => setSiteId(newValue?.value)}
              renderInput={(params) => <TextField {...params} label="Site" />}
            />
          </Grid>
        </Grid>
      )}
      <br />
      {siteId && (
        <form>
          <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Estimate Details</h2>
          <TextInput
            label="Estimate Name (Required)"
            value={salesEstimateFormValues.name}
            shrinkLabel
            valueChanged={(value: string) => setSalesEstimateFormValues({ ...salesEstimateFormValues, name: value })}
          />
          <br />
          <TextInput
            label="Quote Number (Optional)"
            value={salesEstimateFormValues.quote_number}
            shrinkLabel
            valueChanged={(value: string) =>
              setSalesEstimateFormValues({ ...salesEstimateFormValues, quote_number: value })
            }
          />
          <br />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={customerFormOptions?.opportunity_choices}
            getOptionLabel={(option) => option.label}
            sx={{ width: '100%' }}
            value={siteChoices.find((option) => option.value === salesEstimateFormValues?.xoi_job_id)}
            onChange={(event, newValue) =>
              setSalesEstimateFormValues({ ...salesEstimateFormValues, xoi_job_id: newValue.value })
            }
            renderInput={(params) => <TextField {...params} label="Opportunity Name (Optional)" />}
          />
          <br />
          <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Review Customer Details</h2>
          <TextInput
            label="Customer Name (Required)"
            value={customerFormValues?.name}
            shrinkLabel
            valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, name: value })}
          />
          <br />
          <SelectInput
            label="Customer Type (Required)"
            choices={customerFormOptions?.customer_type_choices}
            value={customerFormValues?.customer_type_id}
            valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, customer_type_id: value })}
          />
          <br />
          <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Contact Details</h2>
          <SelectInput
            label="Choose Contact"
            choices={customerFormOptions?.contact_choices}
            value={contactId?.toString()}
            valueChanged={(value: string) => setContactId(parseInt(value))}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                checked={contactFormValues.primary}
                onChange={(event) => setContactFormValues({ ...contactFormValues, primary: event.target.checked })}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Set as Primary Contact"
          />
          <br />
          <TextInput
            label="Contact First Name"
            errorMessage={formErrors?.first_name?.[0]}
            value={contactFormValues?.first_name}
            shrinkLabel
            valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, first_name: value })}
          />
          <br />
          <TextInput
            label="Contact Last Name"
            errorMessage={formErrors?.last_name?.[0]}
            value={contactFormValues?.last_name}
            shrinkLabel
            valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, last_name: value })}
          />
          <br />
          <SelectInput
            label="Contact Type"
            choices={customerFormOptions?.contact_type_choices}
            errorMessage={formErrors?.contact_type_id?.[0]}
            value={contactFormValues?.contact_type_id}
            valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, contact_type_id: value })}
          />
          <br />
          <TextInput
            label="Contact Email"
            errorMessage={formErrors?.email?.[0]}
            value={contactFormValues?.email}
            shrinkLabel
            valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, email: value })}
          />
          <br />
          <TextInput
            label="Phone 1"
            errorMessage={formErrors?.phone1?.[0]}
            value={contactFormValues?.phone1}
            shrinkLabel
            valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, phone1: value })}
          />
          <br />
          <SelectInput
            label="Phone 1 Type"
            errorMessage={formErrors?.phone1code?.[0]}
            value={contactFormValues?.phone1code}
            choices={customerFormOptions.phone_type_choices}
            valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, phone1code: value })}
          />
          <br />
          <TextInput
            label="Phone 2"
            errorMessage={formErrors?.phone2?.[0]}
            value={contactFormValues?.phone2}
            shrinkLabel
            valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, phone2: value })}
          />
          <br />
          <SelectInput
            label="Phone 2 Type"
            errorMessage={formErrors?.phone2code?.[0]}
            value={contactFormValues?.phone2code}
            choices={customerFormOptions.phone_type_choices}
            valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, phone2code: value })}
          />
          <br />
          <hr />
          <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Site Address</h2>
          <TextInput
            label="Location Name"
            errorMessage={formErrors?.name?.[0]}
            value={siteFormValues.name}
            shrinkLabel
            valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, name: value })}
          />
          <br />
          <TextInput
            label="Address 1"
            errorMessage={formErrors?.address1?.[0]}
            value={siteFormValues.address1}
            shrinkLabel
            valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, address1: value })}
          />
          <br />
          <TextInput
            label="Address 2"
            errorMessage={formErrors?.address2?.[0]}
            value={siteFormValues.address2}
            shrinkLabel
            valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, address2: value })}
          />
          <br />
          <TextInput
            label="City"
            errorMessage={formErrors?.city?.[0]}
            value={siteFormValues.city}
            shrinkLabel
            valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, city: value })}
          />
          <br />
          <TextInput
            label="State"
            errorMessage={formErrors?.state?.[0]}
            value={siteFormValues.state}
            shrinkLabel
            valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, state: value })}
          />
          <br />
          <TextInput
            label="Zip"
            errorMessage={formErrors?.zip?.[0]}
            value={siteFormValues.zip}
            shrinkLabel
            valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, zip: value })}
          />
          <br />
          <hr />
          <br />
          <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Review Utility Details</h2>
          <SelectInput
            label="Utility Provider"
            errorMessage={formErrors?.utility_provider_id?.[0]}
            value={salesEstimateFormValues.utility_provider_id}
            choices={providerChoices}
            valueChanged={(value: string) =>
              setSalesEstimateFormValues({ ...salesEstimateFormValues, utility_provider_id: value })
            }
          />
        </form>
      )}
      <br />
      <hr />
      <br />
      {generalErrorMessage && (
        <div style={{ color: 'red', marginBottom: 20, whiteSpace: 'pre-line' }}>{generalErrorMessage}</div>
      )}
      <Button variant="contained" color="primary" onClick={handleUpdate}>
        Select Customer
      </Button>
    </>
  );
};

export default ExistingCustomerForm;
