import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import DataTable from '../../dataTable';
import { rebateDetailsColumns } from '../constants/salesEstimateReviewColumns';
import { SalesEstimate } from '../model';

interface RebateDetailsProps {
  salesEstimate: SalesEstimate;
}

const RebateDetails: React.FunctionComponent<RebateDetailsProps> = (props) => {
  let { salesEstimate } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <h3>Rebate Details</h3>
            <DataTable
              title=""
              columns={rebateDetailsColumns}
              data={salesEstimate?.rebate_details}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RebateDetails;
