import React, { useState } from 'react';
import axios from '../../utils/axios.utils';
import { SelectOption } from '../../form/model';
import { SalesEstimateSiteFormValues } from '../model';
import { SalesEstimateAsset } from '../../estimateDetails/model';

type CustomerFormOptionsType = {
  contact_choices: SelectOption[];
  opportunity_choices: SelectOption[];
  customer_type_choices: SelectOption[];
  contact_type_choices: SelectOption[];
  phone_type_choices: SelectOption[];
};

interface EstimateCustomersContextInterface {
  fetchSites?: () => void;
  siteChoices?: SelectOption[];

  fetchPreexistingRooftopAssets?: (estimate_id: string) => void;

  preexistingRooftopAssets?: Record<string, string>[];
  siteAssetsLoading?: boolean;

  siteFormValues?: SalesEstimateSiteFormValues;
  setSiteFormValues?: (values: SalesEstimateSiteFormValues) => void;

  customerFormOptions?: CustomerFormOptionsType;
  setCustomerFormOptions?: (options: CustomerFormOptionsType) => void;
  fetchCustomerFormOptions?: (customerId: string) => void;
}

interface EstimateCustomersContextProviderProps {
  children: React.ReactNode;
}

const EstimateCustomersContext = React.createContext<EstimateCustomersContextInterface>({});

const EstimateCustomersContextConsumer = EstimateCustomersContext.Consumer;
const EstimateCustomersContextProvider: React.FC<EstimateCustomersContextProviderProps> = ({ children }) => {
  const [preexistingRooftopAssets, setPreexistingRooftopAssets] = useState<Record<string, string>[]>([]);
  const [siteAssetsLoading, setSiteAssetsLoading] = React.useState<boolean>(true);
  const [siteFormValues, setSiteFormValues] = React.useState<SalesEstimateSiteFormValues>();
  const [siteChoices, setSiteChoices] = useState<SelectOption[]>([]);
  const [customerFormOptions, setCustomerFormOptions] = useState<CustomerFormOptionsType>({
    contact_choices: [],
    opportunity_choices: [],
    customer_type_choices: [],
    contact_type_choices: [],
    phone_type_choices: [],
  });

  const fetchCustomerFormOptions = async (customerId) => {
    const response = await axios.get(`/customers/${customerId}/form_options`);
    setCustomerFormOptions(response.data);
  };

  const fetchSites = async () => {
    const response = await axios.get<string, any>('/sites');
    setSiteChoices(response?.data?.result);
  };

  const fetchPreexistingRooftopAssets = async (estimate_id: string) => {
    setSiteAssetsLoading(true);
    const response = await axios.get<string, any>(`/assets?estimate_id=${estimate_id}`);

    setPreexistingRooftopAssets(
      response?.data?.result?.filter((asset: SalesEstimateAsset) => asset.asset_number?.includes('RTU')),
    );
    setSiteAssetsLoading(false);
  };

  return (
    <EstimateCustomersContext.Provider
      value={{
        fetchSites,
        siteChoices,

        fetchPreexistingRooftopAssets,
        preexistingRooftopAssets,
        siteAssetsLoading,

        siteFormValues,
        setSiteFormValues,

        fetchCustomerFormOptions,
        customerFormOptions,
        setCustomerFormOptions,
      }}
    >
      {children}
    </EstimateCustomersContext.Provider>
  );
};

export { EstimateCustomersContextProvider, EstimateCustomersContextConsumer, EstimateCustomersContext };
