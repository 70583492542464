import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, CardContent, Button, Typography } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import TextInput from '../../form/components/TextInput';
import axios from '../../utils/axios.utils';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

function XoiJobsList() {
  const history = useHistory();

  const [xoiJobs, setXoiJobs] = useState<any[]>([]);
  const [xoiJobsFetched, setXoiJobsFetched] = useState<boolean>(false);
  const [showAllJobs, setShowAllJobs] = useState<boolean>(false);

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Customer Name',
      selector: 'customer_name',
      sortable: true,
    },
    {
      name: 'Job Location',
      selector: 'job_location',
      sortable: true,
    },
    {
      name: 'Opportunity',
      selector: 'work_order_number',
      sortable: true,
    },
    {
      name: 'Opened',
      selector: 'xoi_created_at',
      sortable: true,
      cell: (row) => new Date(row.xoi_created_at).toLocaleString(),
      // Change to date only
    },
    {
      name: 'Complete',
      selector: 'xoi_created_at',
      sortable: true,
      cell: (row) => (row.completed_at ? 'Complete' : 'Incomplete'),
      // Change to date only
    },
    {
      name: 'Assignee Emails',
      selector: 'assignee_ids',
      sortable: false,
      cell: (row) => row.assignee_ids.join(', '),
    },
  ];

  const fetchXoiJobs = async (params: any = {}) => {
    axios
      .get<string, any>('/xoi_jobs')
      .then((response) => {
        setXoiJobs(response?.data?.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (!xoiJobsFetched) {
    fetchXoiJobs();
    setXoiJobsFetched(true);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">XOi Jobs</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setShowAllJobs(!showAllJobs)}>
              {showAllJobs ? 'Show TMS Jobs' : 'Show All Jobs'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {/* Default sort by customer name */}
            <DataTable
              title=""
              columns={columns}
              data={showAllJobs ? xoiJobs : xoiJobs.filter((job) => job.work_order_number.includes('TMS-'))}
              onRowClicked={(row) => window.open(`https://visionweb.xoi.io/jobactivity/${row.job_id}`, '_blank')}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default XoiJobsList;
