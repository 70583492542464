import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import DataTable from '../../dataTable';
import ExpandedAssetCost from './ExpandedAssetCost';
import { reviewAssetColumns } from '../constants/salesEstimateReviewColumns';

import { SalesEstimate } from '../model';

interface AssetListProps {
  salesEstimate: SalesEstimate;
}

const AssetList: React.FC<AssetListProps> = (props) => {
  let { salesEstimate } = props;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h3>Asset List</h3>
              <DataTable
                title=""
                columns={reviewAssetColumns}
                data={salesEstimate?.sales_estimate_assets}
                striped
                dense
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={<ExpandedAssetCost row />}
                noHeader
                pagination
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div className="display-only-for-print">
        <ExpandedAssetCost row />
      </div>
    </>
  );
};

export default AssetList;
