import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import NewInstallationCostModal from './NewInstallationCostModal';
import EditInstallationCostModal from './EditInstallationCostModal';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';
import { InstallationCostRecord } from '../../estimateDetails/model';
import { CircularProgress } from '@mui/material';

const InstallationCosts = (props) => {
  let history = useHistory();
  let { salesEstimateId } = props;

  let [newModalOpen, setNewModalOpen] = useState(false);
  let [editModalOpen, setEditModalOpen] = useState(false);
  let [modalTitle, setModalTitle] = useState('Material');
  let [modalUnitLabel, setModalUnitLabel] = useState('Unit');
  let [descriptionOptions, setDescriptionOptions] = useState([]);
  let [recordToEdit, setRecordToEdit] = useState<InstallationCostRecord>(null);

  let { installationCosts, deleteInstallationCostRecord } = useContext(EstimateCostsContext);

  let handleAddButtonClicked = (title: string, unitLabel: string, type: string) => {
    setModalTitle(title);
    setModalUnitLabel(unitLabel);
    console.log('type');
    console.log(type);
    setDescriptionOptions(installationCosts.filter((option: any) => option.primary_category === type));
    setNewModalOpen(true);
  };

  const handleClose = () => setNewModalOpen(false);

  const handleEditButtonClicked = (row) => {
    let primaryCategory = row.primary_category;

    setRecordToEdit(row);
    setDescriptionOptions(installationCosts.filter((option: any) => option.primary_category === primaryCategory));
    setEditModalOpen(true);
  };

  const handleDelete = (row) => {
    deleteInstallationCostRecord(row.id);
  };

  const { salesEstimate } = useContext(EstimateDetailsContext);

  const nonLaborColumns = (): IDataTableColumn<any>[] => {
    return [
      {
        name: 'Asset',
        selector: 'asset_number',
        sortable: true,
        cell: (row) => <p>{row.asset_number}</p>,
      },
      {
        name: 'Description',
        selector: 'name',
        sortable: true,
        cell: (row) => <p>{row.name}</p>,
      },
      {
        name: 'Cost',
        selector: 'total_cost',
        sortable: true,
        cell: (row) => <p>{row.total_cost}</p>,
      },
      {
        name: 'Actions',
        selector: 'actions',
        sortable: true,
        cell: (row) => (
          <div>
            <IconButton onClick={() => handleEditButtonClicked(row)} aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ];
  };

  const laborColumns = (): IDataTableColumn<any>[] => {
    return [
      {
        name: 'Asset',
        selector: 'asset_number',
        sortable: true,
        cell: (row) => <p>{row.asset_number}</p>,
      },
      {
        name: 'Description',
        selector: 'name',
        sortable: true,
        cell: (row) => <p>{row.name}</p>,
      },
      {
        name: 'Hours',
        selector: 'hours',
        sortable: true,
        cell: (row) => <p>{row.hours}</p>,
      },
      {
        name: 'Rate',
        selector: 'rate',
        sortable: true,
        cell: (row) => <p>{row.rate}</p>,
      },
      {
        name: 'Cost',
        selector: 'total_cost',
        sortable: true,
        cell: (row) => <p>{row.total_cost}</p>,
      },
      {
        name: 'Overtime',
        selector: 'overtime',
        sortable: true,
        cell: (row) => <p>{row.overtime ? 'Yes' : 'No'}</p>,
      },
      {
        name: 'Actions',
        selector: 'actions',
        sortable: true,
        cell: (row) => (
          <div>
            <IconButton onClick={() => handleEditButtonClicked(row)} aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ];
  };

  let costGroups = [
    {
      title: 'Material',
      unit_label: 'Unit',
      type: 'material',
      columnsToUse: nonLaborColumns(),
    },
    {
      title: 'Labor',
      unit_label: 'Hour',
      type: 'labor',
      columnsToUse: laborColumns(),
    },
    {
      title: 'Subcontractor',
      unit_label: 'Unit',
      type: 'subcontractor',
      columnsToUse: nonLaborColumns(),
    },
    {
      title: 'Miscellaneous',
      type: 'miscellaneous',
      unit_label: 'Unit',
      columnsToUse: nonLaborColumns(),
    },
  ];

  return salesEstimate?.id ? (
    <Grid container>
      <NewInstallationCostModal
        title={modalTitle}
        unitLabel={modalUnitLabel}
        newModalOpen={newModalOpen}
        handleClose={handleClose}
        salesEstimate={salesEstimate}
        descriptionOptions={descriptionOptions}
      />
      <EditInstallationCostModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        salesEstimate={salesEstimate}
        descriptionOptions={descriptionOptions}
        recordToEdit={recordToEdit}
      />
      {costGroups.map((group) => (
        <Grid item xs={12} style={{ marginBottom: 15 }}>
          <Card style={{ padding: '1%' }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ marginBottom: 8, fontSize: 22 }}>{group.title}</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 15 }}>
                <DataTable
                  title="Assets"
                  columns={group.columnsToUse}
                  data={salesEstimate.sales_estimate_assets?.flatMap((asset) => asset[`${group.type}_costs`])}
                  striped
                  dense
                  highlightOnHover
                  pointerOnHover
                  noHeader
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 15 }}>
                <Button
                  variant="contained"
                  onClick={() => handleAddButtonClicked(group.title, group.unit_label, group.type)}
                  type="button"
                >
                  Add {group.title}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push(`/rooftop_automation/${salesEstimateId}/rebates`)}
      >
        Submit Installation
      </Button>
    </Grid>
  ) : (
    <CircularProgress />
  );
};

export default InstallationCosts;
