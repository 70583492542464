import React, { FC, useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { ProposalDetailBullet } from '../model';

import ProposalDetailBulletFormEdit from './ProposalDetailBulletFormEdit';
import ProposalDetailBulletFormNew from './ProposalDetailBulletFormNew';

interface DetailSubItemsListProps {
  proposalDetailBullets: ProposalDetailBullet[];
  bulletableId: string;
  category: string;
  fetchProposalDetail: () => void;
}

const DetailSubItemsList: FC<DetailSubItemsListProps> = (props) => {
  let { proposalDetailBullets, bulletableId, category, fetchProposalDetail } = props;

  let [newBulletOpen, setNewBulletOpen] = useState<boolean>(false);

  return (
    <Grid container>
      <Grid item xs={12}>
        {proposalDetailBullets?.map((item, index) => (
          <ProposalDetailBulletFormEdit
            key={index}
            proposalDetailBullet={item}
            fetchProposalDetail={fetchProposalDetail}
          />
        ))}
      </Grid>
      {newBulletOpen ? (
        <Grid item xs={12}>
          <ProposalDetailBulletFormNew
            proposalDetailBullet={{
              description: 'New Bullet Point',
              bulletable_id: bulletableId,
              bulletable_type: 'ProposalDetailBullet',
              category: category,
            }}
            fetchProposalDetail={fetchProposalDetail}
            setNewBulletOpen={setNewBulletOpen}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Button
            variant="contained"
            style={{ backgroundColor: 'LightPink', height: '25px', width: '50px', padding: 0 }}
            onClick={() => setNewBulletOpen(true)}
          >
            + Sub
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default DetailSubItemsList;
