import React, { ChangeEvent } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { SystemAccessoriesContext } from '../contexts/SystemAccessoriesContext';
import { SystemAccessoryFormValues, SystemAccessory } from '../model';
import axios from '../../utils/axios.utils';

// This form doesn't currently save the asset_category_l3
// The schema (which is newer than this form) only includes one asset_category_id

interface SystemAccessoryFormProps {
  systemAccessory?: SystemAccessory;
  onSuccess?: () => void;
}

const SystemAccessoryForm: React.FunctionComponent<SystemAccessoryFormProps> = (props) => {
  const { systemAccessory, onSuccess } = props;

  const [choicesFetched, setChoicesFetched] = React.useState(false);
  const [formValues, setFormValues] = React.useState<SystemAccessoryFormValues>({});
  const [systemAccessorySubmitting, setSystemAccessorySubmitting] = React.useState(false);

  const { updateSystemAccessory, fetchChoices, formOptions } = React.useContext(SystemAccessoriesContext);

  React.useEffect(() => {
    setFormValues(systemAccessory);
  }, [systemAccessory]);

  React.useEffect(() => {
    if (!choicesFetched) {
      fetchChoices();
      setChoicesFetched(true);
    }
  }, [fetchChoices, choicesFetched, setChoicesFetched]);

  const handleCreateSystemAccessory = async () => {
    setSystemAccessorySubmitting(true);

    await axios
      .post('system_accessories', { system_accessory: formValues })
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        setSystemAccessorySubmitting(false);
      });
  };

  const handleUpdateSystemAccessory = () => {
    updateSystemAccessory(systemAccessory.id, formValues, onSuccess);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormValues({ ...formValues, submittal_pdf: file });
  };

  return (
    <>
      <br />
      <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextInput
              type="text"
              label="Accessory Name"
              shrinkLabel
              value={formValues?.name}
              valueChanged={(value) => setFormValues({ ...formValues, name: value })}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Asset Category"
              value={formValues?.asset_category_id}
              choices={formOptions?.asset_categories}
              valueChanged={(value) => setFormValues({ ...formValues, asset_category_id: value })}
            />
          </Grid> */}
          <br />
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Accessory Cost"
              shrinkLabel
              value={formValues?.cost}
              valueChanged={(value) => setFormValues({ ...formValues, cost: value.trim() })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              type="text"
              label="Cost UOM"
              value={formValues?.cost_uom}
              choices={[
                { label: '$/kWh', value: 'kWh' },
                { label: '$/ton', value: 'ton' },
                { label: '$/system', value: 'system' },
              ]}
              valueChanged={(value) => setFormValues({ ...formValues, cost_uom: value })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="label"
              sx={{
                fontWeight: 'bold',
                marginBottom: 1,
                display: 'block',
              }}
            >
              Submittal PDF
            </Typography>

            {/* Display the existing file if present */}
            {systemAccessory?.submittal_url && (
              <Typography variant="body2" sx={{ marginBottom: '10px' }}>
                Existing file:{' '}
                <a href={systemAccessory.submittal_url} target="_blank" rel="noopener noreferrer">
                  {systemAccessory.submittal_pdf_name || 'Download PDF'}
                </a>
              </Typography>
            )}

            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="submittal-pdf"
            />
            <label htmlFor="submittal-pdf">
              <Button variant="contained" color="primary" component="span">
                {systemAccessory?.submittal_url ? 'Replace Submittal PDF' : 'Upload Submittal PDF'}
              </Button>
            </label>

            {/* Display the newly selected file */}
            {formValues.submittal_pdf && (
              <Typography variant="body2" style={{ marginTop: '10px' }}>
                Selected file: {formValues.submittal_pdf.name}
              </Typography>
            )}
          </Grid>
        </Grid>
      </form>
      {formValues?.id ? (
        <Button variant="contained" color="primary" onClick={handleUpdateSystemAccessory}>
          Update System Accessory
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={handleCreateSystemAccessory}>
          Create System Accessory
        </Button>
      )}
    </>
  );
};

export default SystemAccessoryForm;
