import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { UsersContext } from '../contexts/UsersContext';
import { UserFormValues, User } from '../model';

interface UserFormProps {
  user?: User;
  onSuccess?: () => void;
}

const UserForm: React.FunctionComponent<UserFormProps> = (props) => {
  const { user, onSuccess } = props;
  const [formValues, setFormValues] = useState<UserFormValues>();
  const [choicesFetched, setChoicesFetched] = useState(false);

  const { updateUser, fetchChoices, userFormChoices } = useContext(UsersContext);

  useEffect(() => {
    setFormValues(user);
  }, [user]);

  useEffect(() => {
    if (!choicesFetched) {
      fetchChoices();
      setChoicesFetched(true);
    }
  }, [fetchChoices, choicesFetched]);

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleUpdateUser = () => {
    updateUser(user?.id || '', formValues, onSuccess);
  };

  const handleRoleChange = (value: string) => {
    setFormValues({ ...formValues, role: value });

    const permissionsByRole = {
      no_access: [
        { value: 'sales_estimates', tier: 'No Access' },
        { value: 'chiller_logs', tier: 'No Access' },
        { value: 'warehouse', tier: 'No Access' },
        { value: 'project_management', tier: 'No Access' },
      ],
      platform_superadmin: [
        { value: 'sales_estimates', tier: 'Superadmin' },
        { value: 'chiller_logs', tier: 'Superadmin' },
        { value: 'warehouse', tier: 'Superadmin' },
        { value: 'project_management', tier: 'Superadmin' },
      ],
      sales_estimate_default_user: [
        { value: 'sales_estimates', tier: 'Read/Write' },
        { value: 'chiller_logs', tier: 'No Access' },
        { value: 'warehouse', tier: 'No Access' },
        { value: 'project_management', tier: 'No Access' },
      ],
      chiller_logs_default_user: [
        { value: 'sales_estimates', tier: 'No Access' },
        { value: 'chiller_logs', tier: 'Read/Write' },
        { value: 'warehouse', tier: 'No Access' },
        { value: 'project_management', tier: 'No Access' },
      ],
      custom: formValues?.modules || [],
    };

    setFormValues({
      ...formValues,
      modules: permissionsByRole[value],
    });
  };

  const handlePermissionTierChange = (moduleValue: string, tier: string) => {
    const modules = formValues?.modules || [];
    setFormValues({
      ...formValues,
      modules: modules.map((mod) => (mod.value === moduleValue ? { ...mod, tier } : mod)),
    });
  };

  const modules = [
    { label: 'Sales Estimates', value: 'sales_estimates' },
    { label: 'Chiller Logs', value: 'chiller_logs' },
    { label: 'Warehouse', value: 'warehouse' },
    { label: 'Project Management', value: 'project_management' },
  ];

  const tiers = ['Superadmin', 'Read/Write', 'Read-Only', 'No Access'];

  return (
    <>
      <br />
      <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="First Name"
              shrinkLabel
              value={formValues?.first_name}
              valueChanged={(value) => handleFormValueChange('first_name', value.trim())}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              type="text"
              label="Last Name"
              shrinkLabel
              value={formValues?.last_name}
              valueChanged={(value) => handleFormValueChange('last_name', value.trim())}
            />
          </Grid>
          <br />
          <Grid item sm={12}>
            <TextInput
              type="text"
              label="Email"
              shrinkLabel
              disabled
              value={formValues?.email}
              valueChanged={(value) => handleFormValueChange('email', value.trim())}
            />
          </Grid>
        </Grid>
      </form>

      <Typography variant="h6" gutterBottom>
        Manage Module Access
      </Typography>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <SelectInput
          type="text"
          label="Select A Role"
          value={formValues?.role}
          choices={[
            { label: 'No Access', value: 'no_access' },
            { label: 'Platform Superadmin', value: 'platform_superadmin' },
            { label: 'Sales Estimate Default User', value: 'sales_estimate_default_user' },
            { label: 'Chiller Logs Default User', value: 'chiller_logs_default_user' },
            { label: 'Custom', value: 'custom' },
          ]}
          valueChanged={(value) => handleRoleChange(value)}
        />
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Module</strong>
            </TableCell>
            {tiers.map((tier) => (
              <TableCell key={tier}>
                <strong>{tier}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {modules.map((mod) => {
            const currentTier = formValues?.modules?.find((m) => m.value === mod.value)?.tier || 'No Access';

            return (
              <TableRow key={mod.value}>
                <TableCell>{mod.label}</TableCell>
                {tiers.map((tier) => (
                  <TableCell key={`${mod.value}-${tier}`}>
                    <RadioGroup
                      value={currentTier}
                      onChange={(e) => handlePermissionTierChange(mod.value, e.target.value)}
                    >
                      <FormControlLabel
                        value={tier}
                        control={<Radio disabled={formValues?.role !== 'custom'} />}
                        label=""
                      />
                    </RadioGroup>
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Button variant="contained" color="primary" onClick={handleUpdateUser} style={{ marginTop: '20px' }}>
        Update User
      </Button>
    </>
  );
};

export default UserForm;
