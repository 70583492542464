import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import axios from '../../utils/axios.utils';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Asset Name',
    selector: 'asset_number',
    sortable: false,
  },
  {
    name: 'Customer',
    selector: 'customer_name',
    sortable: false,
  },
  {
    name: 'Site',
    selector: 'site_name',
    sortable: false,
  },
  {
    name: 'Manufacturer',
    selector: 'manufacturer',
    sortable: false,
  },
  {
    name: 'Model',
    selector: 'model_number',
    sortable: false,
  },
  {
    name: 'Serial',
    selector: 'serial_number',
    sortable: false,
  },
];

const ServmanAssetsList: React.FunctionComponent = () => {
  const [data, setData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(100); // Default rows per page

  const fetchCustomers = async (page: number, rowsPerPage: number) => {
    setLoading(true);

    try {
      const response = await axios.get<any, any>('/servman_data/assets', {
        params: {
          page,
          per_page: rowsPerPage,
        },
      });
      setData(response.data.result);
      setTotalRows(response.data.record_count);
    } catch (error) {
      console.error('Error fetching customers:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ marginTop: 5 }}>
              Servman Assets
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title=""
              columns={columns}
              data={data}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangePage={handlePageChange}
              paginationRowsPerPageOptions={[100]}
              paginationPerPage={100}
              progressPending={loading}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ServmanAssetsList;
