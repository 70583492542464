import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { GOOGLE_API_KEY } from '../../utils/env';
import { SystemAccessoriesContext } from '../../adminSettings/contexts/SystemAccessoriesContext';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';

import CurbAdapterSubForm from './CurbAdapterSubForm';

import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';

import axios from '../../utils/axios.utils';

const useStyles = makeStyles((theme: any) => ({
  mapContainer: {
    '@media (max-width: 1200px)': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
    },
  },
  paddedGrid: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
    },
  },
}));

const NewCustomerForm = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const { salesEstimateId } = props;

  const [craneFormValues, setCraneFormValues] = React.useState<Record<string, string>>({});

  const { salesEstimate } = React.useContext(EstimateDetailsContext);
  const { updateCraneDetails, craneDetails, updateCurbAdapter } = React.useContext(EstimateCostsContext);

  useEffect(() => {
    setCraneFormValues({
      unit_to_building_edge: craneDetails?.unit_to_building_edge,
      unit_to_center_pin: craneDetails?.unit_to_center_pin,
      building_height: craneDetails?.building_height,
      max_unit_weight: craneDetails?.max_unit_weight,
      estimated_cost: craneDetails?.estimated_cost,
    });
  }, [craneDetails]);

  const handleCraneSubmit = () => {
    updateCraneDetails(salesEstimateId, craneFormValues);
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Crane Costs</h2>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.mapContainer}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '400px' }}
            center={salesEstimate?.address_coordinates}
            zoom={20}
          >
            <Marker position={salesEstimate?.address_coordinates} />
          </GoogleMap>
        </Grid>
        <br />
        <Grid item xs={12} lg={6} className={classes.paddedGrid}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextInput label="Site Address" value={salesEstimate?.address} shrinkLabel />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextInput
                label="Unit to Building Edge (Tooltip)"
                value={craneFormValues.unit_to_building_edge}
                valueChanged={(value) => setCraneFormValues({ ...craneFormValues, unit_to_building_edge: value })}
                shrinkLabel
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextInput
                label="Unit to Center Pin (Tooltip)"
                value={craneFormValues.unit_to_center_pin}
                valueChanged={(value) => setCraneFormValues({ ...craneFormValues, unit_to_center_pin: value })}
                shrinkLabel
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextInput
                label="Building Height (Tooltip)"
                value={craneFormValues.building_height}
                valueChanged={(value) => setCraneFormValues({ ...craneFormValues, building_height: value })}
                shrinkLabel
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextInput
                label="Maximum Unit Weight"
                value={craneFormValues.max_unit_weight}
                valueChanged={(value) => setCraneFormValues({ ...craneFormValues, max_unit_weight: value })}
                shrinkLabel
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextInput
                label="Estimated Cost"
                value={craneFormValues.estimated_cost}
                valueChanged={(value) => setCraneFormValues({ ...craneFormValues, estimated_cost: value })}
                shrinkLabel
                currency
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleCraneSubmit} type="button">
                Save Crane Details
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <hr />
      <br />
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item xs={12}>
          <h2 style={{ fontSize: '18px', marginBottom: 10 }}>Curb Adapters</h2>
        </Grid>
        {salesEstimate?.sales_estimate_assets?.map((asset, index) => (
          <Grid item key={index} xs={12} md={6} style={{ marginBottom: 20 }}>
            <CurbAdapterSubForm curbAdapter={asset.curb_adapter} salesEstimateAsset={asset} />
          </Grid>
        ))}
      </Grid>
      <br />
      <hr />
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push(`/rooftop_automation/${salesEstimateId}/installation_costs`)}
      >
        Submit Crane and Curb Adapters
      </Button>
    </>
  );
};

export default NewCustomerForm;
