import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { SalesEstimate } from '../model';

import axios from '../../utils/axios.utils';

interface MarginDetailsProps {
  salesEstimate: SalesEstimate;
  setSalesEstimate: Dispatch<SetStateAction<SalesEstimate>>;
}

const MarginDetails: React.FC<MarginDetailsProps> = (props) => {
  let { salesEstimate, setSalesEstimate } = props;

  const [grossProfitInteger, setGrossProfitInteger] = useState(salesEstimate?.gross_profit_integer);

  useEffect(() => {
    setGrossProfitInteger(salesEstimate?.gross_profit_integer);
  }, [salesEstimate]);

  const handleSaveGrossProfitInteger = async () => {
    let response: any = await axios.put(`sales_estimates/${salesEstimate?.id}/gross_profit_integer`, {
      sales_estimate: { gross_profit_integer: grossProfitInteger },
    });

    let result = response?.data?.result;

    setGrossProfitInteger(result.gross_profit_integer);
    setSalesEstimate({
      ...salesEstimate,
      gross_profit_integer: result.gross_profit_integer,
      gross_profit_display: result.gross_profit_display,
      gross_profit_per_man_day: result.gross_profit_per_man_day,
      sale_price: result.sale_price,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <h3>Margin Details</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography gutterBottom>Gross Profit (%)</Typography>
                <TextField
                  type="number"
                  value={grossProfitInteger}
                  fullWidth
                  sx={{
                    '& input': {
                      padding: '8px',
                    },
                  }}
                  onChange={(e) => setGrossProfitInteger(e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 25,
                      max: 36,
                    },
                  }}
                  aria-labelledby="unit-repair-cost-input"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  style={{ marginTop: 31 }}
                  variant="contained"
                  color="primary"
                  onClick={handleSaveGrossProfitInteger}
                >
                  Save GP %
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div">
                  Gross Profit:
                </Typography>
                <Typography>{salesEstimate?.gross_profit_display}</Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div">
                  Total Hours:
                </Typography>
                <Typography>{salesEstimate?.total_hours}</Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div">
                  GPPMD:
                </Typography>
                <Typography>{salesEstimate?.gross_profit_per_man_day}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MarginDetails;
