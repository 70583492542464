import React, { useState, useEffect, useContext } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

import { Rebate } from '../model';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

import axios from '../../utils/axios.utils';

interface RebateOptionCardProps {
  rebate: Rebate;
}

const RebateOptionCard: React.FC<RebateOptionCardProps> = (props) => {
  let { rebate } = props;

  let { salesEstimate, setSalesEstimate } = useContext(EstimateDetailsContext);

  let [included, setIncluded] = useState(false);
  let [backgroundColor, setBackgroundColor] = useState('aliceblue');
  let [textColor, setTextColor] = useState('DarkSlateGrey');

  useEffect(() => {
    if (salesEstimate.rebate_ids?.includes(rebate.id)) {
      setIncluded(true);
      setBackgroundColor('#1976d2');
      setTextColor('#fff');
    } else {
      setIncluded(false);
      setBackgroundColor('aliceblue');
      setTextColor('DarkSlateGrey');
    }
  }, [salesEstimate]);

  let handleCardClicked = async () => {
    let response: any;

    if (included) {
      response = await axios.delete('rebate_records/0', {
        data: {
          rebate_id: rebate.id,
          sales_estimate_id: salesEstimate.id,
        },
      });
    } else {
      response = await axios.post('rebate_records', {
        rebate_id: rebate.id,
        sales_estimate_id: salesEstimate.id,
      });
    }

    let updatedRebateIds = response.data.rebate_ids;

    setSalesEstimate({ ...salesEstimate, rebate_ids: updatedRebateIds });
  };

  return (
    <Card
      variant={'outlined'}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: 0,
        padding: '10px',
      }}
    >
      <CardActionArea onClick={handleCardClicked}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography gutterBottom variant="h5" component="div">
                {rebate.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: textColor }}>
                Technology: {rebate.technology}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: textColor }}>
                Specifx L2 Description: {rebate.l2_description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: textColor }}>
                Source: {rebate.source}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: textColor }}>
                Rebate Rate:
                {rebate.min_rebate_rate === rebate.max_rebate_rate
                  ? rebate.min_rebate_rate
                  : `${rebate.min_rebate_rate}-${rebate.max_rebate_rate}/${rebate.rebate_uom}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default RebateOptionCard;
