import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import InformationField from '../../form/components/InformationField';
import SelectInput from '../../form/components/SelectInput';
import TextInput from '../../form/components/TextInput';
import DataTable from '../../dataTable';

import { RebateRecord, Comment, HistoryItem } from '../model';

import axios from '../../utils/axios.utils';

let stageChoices = [
  { value: 'Review Needed', label: 'Review Needed' },
  { value: 'Submitted', label: 'Submitted' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
];

let mockRebateHistory = [
  {
    column: 'Stage',
    from: 'Review Needed',
    to: 'Submitted',
    time: '2021-10-01 12:00:00',
    user: 'Mr. Tudi',
    commentFormValues: 'Approval from Duquense Sandwiches',
  },
  {
    column: 'Total Rebate Amount',
    from: '0',
    to: '1000',
    time: '2021-10-02 12:00:00',
    user: 'Rebate Manager',
    commentFormValues: 'Estimated Rebate Amount',
  },
];

let rebateRecordHistoryColumns = [
  { name: 'Time', selector: 'created_at', sortable: true },
  {
    name: 'Field',
    cell: (row: any) => (
      <div>{row.history_item_lines?.map((line: any) => <p style={{ marginBottom: 5 }}>{line.field}</p>)}</div>
    ),
  },
  {
    name: 'From',
    cell: (row: any) => (
      <div>{row.history_item_lines?.map((line: any) => <p style={{ marginBottom: 5 }}>{line.before_name}</p>)}</div>
    ),
  },
  {
    name: 'To',
    cell: (row: any) => (
      <div>{row.history_item_lines?.map((line: any) => <p style={{ marginBottom: 5 }}>{line.after_name}</p>)}</div>
    ),
  },
  { name: 'User', selector: 'user', cell: (row: any) => 'Mr. Tudi', sortable: true },
  { name: 'Comment', selector: 'comment', sortable: true, grow: 2 },
];

const RebateRecordDetailPage: React.FC = () => {
  const { rebateRecordId } = useParams<Record<string, string>>();

  let [rebateRecordFormValues, setRebateRecordFormValues] = useState<RebateRecord>();
  let [commentFormValues, setCommentFormValues] = useState<Comment>();
  let [historyItems, setHistoryItems] = useState<HistoryItem[]>([]);

  const fetchRebateRecord = async () => {
    try {
      const response: any = await axios.get(`/rebate_records/${rebateRecordId}`);
      const result = response.data.result;
      setRebateRecordFormValues(result);
    } catch (error) {
      console.error('Error fetching rebate record:', error);
    }
  };

  const fetchHistoryItems = async () => {
    try {
      const response: any = await axios.get('/history_items', {
        params: {
          historyable_id: rebateRecordId,
          historyable_type: 'RebateRecord',
        },
      });
      setHistoryItems(response.data.result);
    } catch (error) {
      console.error('Error fetching history items:', error);
    }
  };

  const loadAllPageData = async () => {
    try {
      await Promise.all([fetchRebateRecord(), fetchHistoryItems()]);
    } catch (error) {
      console.error('Error loading page data:', error);
    }
  };

  useEffect(() => {
    loadAllPageData();
  }, [rebateRecordId]); // Add `rebateRecordId` if it might change

  let handleUpdate = async () => {
    let response: any = await axios.put(`/rebate_records/${rebateRecordId}`, {
      rebate_record: rebateRecordFormValues,
      comment: commentFormValues,
    });
    setRebateRecordFormValues(response.data.result);
    fetchHistoryItems();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <h3 style={{ fontSize: '20px' }}>Rebate Record #{rebateRecordFormValues?.id}</h3>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <h2 style={{ fontSize: '24px' }}>{rebateRecordFormValues?.sales_estimate_name}</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item>
            <InformationField label="Name" value={rebateRecordFormValues?.name} />
          </Grid>
          <Grid item>
            <InformationField label="Source" value={rebateRecordFormValues?.source} />
          </Grid>
          <Grid item>
            <InformationField label="Last Updated" value={rebateRecordFormValues?.updated_at} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Card style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item>
              <InformationField label="Utility Provider" value={rebateRecordFormValues?.utility_provider} />
            </Grid>
            <Grid item>
              <InformationField label="Rebate UOM" value={rebateRecordFormValues?.rebate_uom} />
            </Grid>
            <Grid item>
              <InformationField label="Job Units" value={rebateRecordFormValues?.job_units} />
            </Grid>
            <Grid item>
              <InformationField label="Savings Per Unit" value={rebateRecordFormValues?.savings_per_unit} />
            </Grid>
            <Grid item>
              <InformationField label="Total Rebate" value={rebateRecordFormValues?.total_rebate} />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Stage"
                value={rebateRecordFormValues?.stage}
                valueChanged={(value) => setRebateRecordFormValues({ ...rebateRecordFormValues, stage: value })}
                choices={stageChoices}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="Total Rebate Amount"
                value={rebateRecordFormValues?.total_rebate}
                valueChanged={(value) => setRebateRecordFormValues({ ...rebateRecordFormValues, total_rebate: value })}
                shrinkLabel
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Comment"
                value={commentFormValues?.body}
                valueChanged={(value) => setCommentFormValues({ ...commentFormValues, body: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleUpdate}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary>Rebate History</AccordionSummary>
          <AccordionDetails>
            <DataTable columns={rebateRecordHistoryColumns} data={historyItems} striped noHeader />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default RebateRecordDetailPage;
