import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import axios from '../../utils/axios.utils';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Module Access',
    selector: 'module_access',
    sortable: true,
  },
  {
    name: 'Last Login',
    selector: 'last_login_date',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
];

const UserListPage: React.FunctionComponent = () => {
  const history = useHistory();

  const [users, setUsers] = useState<any>([]);

  const fetchUsers = async () => {
    await axios.get('/users').then((response: any) => {
      setUsers(response.data.result);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h5" component="h5" style={{ marginTop: 5 }}>
              Tudi Users
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <DataTable
              title="Users"
              columns={columns}
              data={users}
              onRowClicked={(row) => history.push(`/admin/user_management/${row.id}/edit`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
              pagination
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default UserListPage;
