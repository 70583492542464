import React, { Dispatch, SetStateAction, useState, useContext } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import SelectInput from '../../form/components/SelectInput';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { SystemAccessoriesContext } from '../../adminSettings/contexts/SystemAccessoriesContext';

import { SystemAccessoryRecord } from '../../estimateDetails/model';

import axios from '../../utils/axios.utils';

interface SystemAccessoryRecordFormRowProps {
  systemAccessoryRecords: SystemAccessoryRecord[];
  setSystemAccessoryRecords: Dispatch<SetStateAction<SystemAccessoryRecord[]>>;
  index: number;
}

let styles = {
  formField: {
    marginBottom: 15,
  },
};

const SystemAccessoryRecordFormRow: React.FC<SystemAccessoryRecordFormRowProps> = (props) => {
  let { systemAccessoryRecords, setSystemAccessoryRecords, index } = props;

  let { salesEstimate } = useContext(EstimateDetailsContext);
  let { systemAccessories } = useContext(SystemAccessoriesContext);

  let [record, setRecord] = useState<SystemAccessoryRecord>(systemAccessoryRecords[index]);

  let assetChoices = salesEstimate.sales_estimate_assets.map((sales_estimate_asset) => {
    return {
      value: sales_estimate_asset.id,
      label: sales_estimate_asset.asset?.asset_number,
    };
  });

  assetChoices.unshift({ value: '0', label: 'Standalone Accessory' });
  assetChoices.unshift({ value: '-1', label: 'Assign to All' });

  let systemAccessoryChoices = systemAccessories.map((accessory) => {
    return {
      value: accessory.id.toString(),
      label: `${accessory.name}`,
    };
  });

  // Add the "Other" option
  systemAccessoryChoices.push({ value: 'other', label: 'Other' });

  let handleFormChanged = (key: any, event: any) => {
    let newRecord = { ...record, [key]: event.target.value };

    // If the user selects an existing accessory
    if (key === 'system_accessory_id' && event.target.value !== 'other') {
      let systemAccessory = systemAccessories.find((accessory) => accessory.id.toString() === event.target.value);
      if (systemAccessory) {
        newRecord.cost = systemAccessory.cost;
        newRecord.custom_accessory_name = undefined; // Clear custom name if previously set
      }
    }

    // If the user chooses 'Other', we clear the cost since it's custom
    if (key === 'system_accessory_id' && event.target.value === 'other') {
      newRecord.cost = '';
      // Initialize a field to hold the custom accessory name
      newRecord.custom_accessory_name = '';
      // newRecord.system_accessory_id = '-1';
    }

    setRecord(newRecord);

    let newRecords = [...systemAccessoryRecords];
    newRecords[index] = newRecord;
    setSystemAccessoryRecords(newRecords);
  };

  let removeNonIdRecord = () => {
    let newRecords = systemAccessoryRecords.filter((_, i) => i !== index);
    setSystemAccessoryRecords(newRecords);
  };

  let removeDatabaseRecord = async () => {
    let response: any = await axios.delete(`/system_accessory_records/${record.id}`);
    let updatedRecords = response.data.result;
    setSystemAccessoryRecords(updatedRecords);
  };

  let handleRemove = () => {
    if (record.id) {
      removeDatabaseRecord();
    } else {
      removeNonIdRecord();
    }
  };

  return (
    <Card style={{ marginBottom: 15, padding: 10 }}>
      <Grid container spacing={3}>
        <Grid item md={3} xs={6} style={styles.formField}>
          <SelectInput
            label="Asset"
            value={record.sales_estimate_asset_id}
            choices={assetChoices}
            onChange={(event) => {
              handleFormChanged('sales_estimate_asset_id', event);
            }}
          />
        </Grid>
        <Grid item md={3} xs={6} style={styles.formField}>
          <SelectInput
            label="Item Name"
            value={record.system_accessory_id}
            choices={systemAccessoryChoices}
            onChange={(event) => {
              handleFormChanged('system_accessory_id', event);
            }}
          />
        </Grid>

        {/* Conditionally render the custom accessory name field if "Other" is chosen */}
        {record.system_accessory_id === 'other' && (
          <Grid item md={3} xs={6} style={styles.formField}>
            <TextField
              label="Custom Accessory Name"
              value={record.custom_accessory_name || ''}
              onChange={(event) => {
                handleFormChanged('custom_accessory_name', event);
              }}
            />
          </Grid>
        )}

        <Grid item md={3} xs={6} style={styles.formField}>
          <TextField
            label="Cost"
            value={record.cost}
            onChange={(event) => {
              handleFormChanged('cost', event);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item md={2} xs={6} style={styles.formField}>
          <Button variant="contained" color="warning" onClick={handleRemove}>
            Remove
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SystemAccessoryRecordFormRow;
