import React, { FC, useState, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import SelectInput from '../../form/components/SelectInput';
import TextInput from '../../form/components/TextInput';
import axios from '../../utils/axios.utils';

import { InstallationCostRecord } from '../../estimateDetails/model';

interface InstallationCostModalProps {
  newModalOpen: boolean;
  handleClose: () => void;
  title: string;
  unitLabel: string;
  salesEstimate: any;
  descriptionOptions?: any[];
}

const InstallationCostModal: FC<InstallationCostModalProps> = (props) => {
  let { newModalOpen, handleClose, title, unitLabel, salesEstimate, descriptionOptions = [] } = props;

  let [formValues, setFormValues] = useState<InstallationCostRecord>({});
  let [materialCategory, setMaterialCategory] = useState('');
  let [totalCost, setTotalCost] = useState(0);
  let [estimateAssetChoices, setEstimateAssetChoices] = useState([]);

  const rateWithOvertime = () => {
    return formValues?.overtime ? parseFloat(formValues?.cost_per_unit) * 1.5 : formValues?.cost_per_unit;
  };

  useEffect(() => {
    let total = parseFloat(formValues.cost_per_unit) * parseFloat(formValues.job_units) || 0;
    setTotalCost(total);
  }, [formValues]);

  useEffect(() => {
    let choices = salesEstimate.sales_estimate_assets?.flatMap((asset) => ({
      label: asset.asset_number,
      value: asset.id,
    }));
    setEstimateAssetChoices(choices);
    let defaultAssetId = choices?.[0]?.value;

    let defaultCostPerUnit = 0;
    if (title === 'Labor') defaultCostPerUnit = 100;

    setFormValues({
      ...formValues,
      sales_estimate_asset_id: defaultAssetId?.toString(),
      cost_per_unit: defaultCostPerUnit?.toString(),
    });
  }, [title]);

  // Update total cost independently to avoid circular re-rendering.
  useEffect(() => {
    const total = parseFloat(formValues?.cost_per_unit) * parseFloat(formValues?.job_units || '0');
    const overtimeFactor = formValues?.overtime ? 1.5 : 1;
    setTotalCost(total * overtimeFactor);
  }, [formValues?.cost_per_unit, formValues?.job_units, formValues?.overtime]);

  let handleSubmit = async () => {
    await axios.post('/installation_cost_records', { installation_cost_record: formValues });
    window.location.reload();
  };

  return (
    <Dialog open={newModalOpen} onClose={handleClose}>
      <DialogTitle>Add New {title} Cost</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <SelectInput
          label="Asset Number"
          value={formValues.sales_estimate_asset_id}
          valueChanged={(value) => setFormValues({ ...formValues, sales_estimate_asset_id: value })}
          choices={estimateAssetChoices}
        />
        <br />
        <SelectInput
          label="Description"
          value={formValues.installation_cost_id}
          valueChanged={(value) => {
            setFormValues({ ...formValues, installation_cost_id: value });
            setMaterialCategory(descriptionOptions.filter((option) => option.value === value)[0]?.label);
          }}
          choices={descriptionOptions}
        />
        <br />
        {materialCategory === 'Other' && (
          <>
            <TextInput
              label="Other Description"
              value={formValues.description_override}
              shrinkLabel
              valueChanged={(value) => setFormValues({ ...formValues, description_override: value })}
            />
            <br />
          </>
        )}
        <TextInput
          label={`Cost per ${unitLabel}`}
          value={formValues.cost_per_unit}
          shrinkLabel
          currency
          valueChanged={(value) => setFormValues({ ...formValues, cost_per_unit: value })}
        />
        <br />
        <TextInput
          label={`${unitLabel}s`}
          value={formValues.job_units}
          shrinkLabel
          valueChanged={(value) => setFormValues({ ...formValues, job_units: value })}
        />
        <br />
        {title === 'Labor' && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues?.overtime}
                  onChange={(e) => setFormValues({ ...formValues, overtime: e.target.checked })}
                />
              }
              label="Overtime"
            />
            {formValues?.overtime && (
              <Typography style={{ marginTop: 10 }}>Rate with Overtime: ${rateWithOvertime()}</Typography>
            )}
          </>
        )}
        <Typography style={{ marginTop: 10 }}>Total Cost: ${totalCost?.toFixed(2) || ''}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" onClick={handleSubmit}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstallationCostModal;
