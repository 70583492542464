import React from 'react';
import axios from '../../utils/axios.utils';
import { UserFormValues, User } from '../model';
import { SelectOption } from '../../form/model';

interface UserFormChoicesType {
  modules: SelectOption[];
}

interface UsersContextInterface {
  fetchUsers?: () => void;
  users?: User[];
  usersLoading?: boolean;

  fetchUser?: (user_id: string) => void;
  user?: User;
  userLoading?: boolean;

  fetchChoices?: () => void;
  userFormChoices?: UserFormChoicesType;

  formChoices?: SelectOption[];
  updateUser?: (user_id: string, formValues: UserFormValues, onSucess: () => void) => void;
}

interface UsersContextProviderProps {
  children: React.ReactNode;
}

const UsersContext = React.createContext<UsersContextInterface>({});

const UsersContextConsumer = UsersContext.Consumer;
const UsersContextProvider: React.FC<UsersContextProviderProps> = ({ children }) => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [usersLoading, setUsersLoading] = React.useState<boolean>(true);

  const [user, setUser] = React.useState<User>({});
  const [userLoading, setUserLoading] = React.useState<boolean>(true);

  const [userFormChoices, setUserFormChoices] = React.useState<UserFormChoicesType>();

  const [userSubmitting, setUserSubmitting] = React.useState<boolean>(false);

  const fetchUsers = async () => {
    const response = await axios.get<string, any>('/users');

    setUsers(response?.data?.result);
    setUsersLoading(false);
  };

  const fetchUser = async (userId: string) => {
    const response = await axios.get<string, any>(`/users/${userId}`);

    setUser(response?.data?.result);
    setUserLoading(false);
  };

  const fetchChoices = async () => {
    setUserFormChoices({
      modules: [
        { label: 'Sales Estimates', value: '1' },
        { label: 'Admin', value: '2' },
      ],
    });
  };

  const updateUser = async (userId: string, formValues: UserFormValues, onSucess: () => void) => {
    setUserSubmitting(true);

    await axios
      .put<UserFormValues, any>(`/users/${userId}`, { ...formValues })
      .then((response) => {
        onSucess();
      })
      .catch((error) => {
        setUserSubmitting(false);
      });
  };

  return (
    <UsersContext.Provider
      value={{
        fetchUsers,
        users,
        usersLoading,

        fetchUser,
        user,
        userLoading,

        fetchChoices,
        userFormChoices,
        updateUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { UsersContextProvider, UsersContextConsumer, UsersContext };
