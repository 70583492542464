import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/styles';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Box,
  Card,
  CardContent,
  InputAdornment,
  Popper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';
import InformationField from '../../form/components/InformationField';
import axios from '../../utils/axios.utils';
import ExpandedSpecifxDetails from './ExpandedSpecifxDetails';
import ReplacementEquipmentFilterBar from './ReplacementEquipmentFilterBar';

import SystemAccessoryRecordFormRow from './SystemAccessoryRecordFormRow';

// Show actual values in Dialog modal?

// Mobile styles for both of these pages

const CustomPopper = styled(Popper)(({ theme }) => ({}));

const filterChoices = {
  manufacturer_choices: [
    { label: 'Carrier', value: 'carrier' },
    { label: 'Trane', value: '2' },
  ],
  tons_choices: [
    { label: '3-5', value: 'small' },
    { label: '6-12.5', value: 'medium' },
    { label: '15-25', value: 'large' },
    { label: '25+', value: 'xlarge' },
  ],
  heating_choices: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ],
  orientation_choices: [
    { label: 'Horizontal', value: 'horizontal' },
    { label: 'Vertical', value: 'vertical' },
    { label: 'Combo', value: 'combo' },
  ],
};

const EquipmentCostForm = (props) => {
  let history = useHistory();
  let { salesEstimateId } = props;

  let { salesEstimate } = React.useContext(EstimateDetailsContext);
  let { equipmentCostOptions } = React.useContext(EstimateCostsContext);

  let [assetDetailsModalOpen, setAssetDetailsModalOpen] = useState(false);
  let [modalDetails, setModalDetails] = useState([]);

  let [equipmentCostRecords, setEquipmentCostRecords] = useState([]);
  let [systemAccessoryRecords, setSystemAccessoryRecords] = useState([]);

  const handleOpen = (details) => {
    setModalDetails(details);
    setAssetDetailsModalOpen(true);
  };

  const handleClose = () => {
    setAssetDetailsModalOpen(false);
    setModalDetails([]);
  };

  let addSystemAccessoryRecord = () => {
    let newSystemAccessoryRecords = [...systemAccessoryRecords];
    newSystemAccessoryRecords.push({});
    setSystemAccessoryRecords(newSystemAccessoryRecords);
  };

  useEffect(() => {
    let ecrs = salesEstimate.sales_estimate_assets.map((sea) => {
      return { ...sea.equipment_cost_record, sales_estimate_asset_id: sea.id };
    });
    setEquipmentCostRecords(ecrs);
    setSystemAccessoryRecords(salesEstimate.system_accessory_records);
  }, [salesEstimate]);

  const handleValueChange = (index, field, value) => {
    const updatedEquipmentCostRecords = [...equipmentCostRecords];
    updatedEquipmentCostRecords[index] = { ...updatedEquipmentCostRecords[index], [field]: value };
    setEquipmentCostRecords(updatedEquipmentCostRecords);
  };

  const handleEquipmentSelected = (index, value) => {
    // set the equipment cost id, and also the set the cost to equal the default cost of the equipment that was chosen
    const updatedEquipmentCostRecords = [...equipmentCostRecords];
    updatedEquipmentCostRecords[index] = {
      ...updatedEquipmentCostRecords[index],
      equipment_cost_id: value.value,
      total_cost: value.default_cost,
    };
    setEquipmentCostRecords(updatedEquipmentCostRecords);
  };

  const onSubmit = () => {
    axios
      .post(`/sales_estimates/${salesEstimateId}/equipment_replacements`, {
        equipment_cost_records: equipmentCostRecords,
        system_accessory_records: systemAccessoryRecords,
      })
      .then(() => {
        history.push(`/rooftop_automation/${salesEstimateId}/crane_curb`);
      });
  };

  return (
    <Grid container>
      <Dialog open={assetDetailsModalOpen} onClose={handleClose}>
        <DialogTitle>Additional Details</DialogTitle>
        <DialogContent>
          <div className="expanded-row" style={{ width: '100%' }}>
            <Grid container spacing={3}>
              {modalDetails?.map((key_value) => (
                <Grid item xs={4}>
                  <InformationField label={key_value.label} value={key_value.value || 'Unknown'} />
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <ReplacementEquipmentFilterBar filterChoices={filterChoices} />
      </Grid>
      <Grid item xs={12}>
        {salesEstimate?.sales_estimate_assets?.map((sales_estimate_asset, index) => (
          <Card key={index} style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={3} style={{ display: 'flex' }}>
                <Grid item style={{ width: '40%' }}>
                  <Typography style={{ fontSize: '20px' }}>
                    <span style={{ fontWeight: 'bold' }}>{sales_estimate_asset.asset.asset_number}</span> (Full)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>size: {sales_estimate_asset.asset.size}</Typography>
                </Grid>
              </Grid>
              <br />
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12} md={2}>
                  <Typography>Replace with:</Typography>
                </Grid>
                <Grid item xs={12} md={8} style={{ marginBottom: 15 }}>
                  <Autocomplete
                    disablePortal
                    PopperComponent={CustomPopper}
                    id="combo-box-demo"
                    options={[{ label: 'Select Replacement', value: 'Select Replacement' }, ...equipmentCostOptions]}
                    getOptionLabel={(option: any) => option.label}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props} display="flex" alignItems="center">
                        {option.label}
                        {option.details && (
                          <IconButton
                            edge="end"
                            aria-label="details"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpen(option.details);
                            }}
                          >
                            <InfoIcon />
                          </IconButton>
                        )}
                      </Box>
                    )}
                    sx={{ width: '95%', float: 'left' }}
                    value={
                      equipmentCostOptions.filter(
                        (option) => option.value === equipmentCostRecords[index]?.equipment_cost_id,
                      )[0] || { label: 'Select Replacement', value: 'Select Replacement' }
                    }
                    onChange={(event, newValue: any) => handleEquipmentSelected(index, newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    label="Equipment Cost"
                    variant="outlined"
                    value={equipmentCostRecords[index]?.total_cost}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onChange={(event) => handleValueChange(index, 'total_cost', event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 10 }}>
                <Grid item style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                  <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#dcf5e6' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Specifx Decoded Asset Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ExpandedSpecifxDetails salesEstimateAsset={sales_estimate_asset} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Grid>
      <Grid item xs={12}>
        <h2 style={{ fontSize: '18px', marginBottom: 10 }}>System Accessories</h2>
      </Grid>
      <Grid item xs={12}>
        {systemAccessoryRecords.map((record, index) => (
          <SystemAccessoryRecordFormRow
            index={index}
            systemAccessoryRecords={systemAccessoryRecords}
            setSystemAccessoryRecords={setSystemAccessoryRecords}
          />
        ))}
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Button onClick={() => addSystemAccessoryRecord()} variant="contained" color="secondary">
          Add System Accessory
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Submit Equipment Details
        </Button>
      </Grid>
    </Grid>
  );
};

export default EquipmentCostForm;
