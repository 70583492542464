import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import DataTable from '../../dataTable';
import ExpandedCostBreakdown from './ExpandedCostBreakdown';

import { costSummaryColumns } from '../constants/salesEstimateReviewColumns';

import { SalesEstimate } from '../model';

interface CostSummaryProps {
  salesEstimate: SalesEstimate;
}

const CostSummary: React.FC<CostSummaryProps> = (props) => {
  let { salesEstimate } = props;

  return (
    <>
      <Grid container spacing={3} className="display-only-for-print">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h3>Cost Summary</h3>
              <DataTable
                title=""
                columns={[
                  {
                    name: 'Category',
                    selector: 'category',
                    sortable: true,
                    cell: (row) => <p style={{ textTransform: 'capitalize', fontWeight: 700 }}>{row.category}</p>,
                  },
                  {
                    name: 'Total Cost',
                    selector: 'total_cost',
                    sortable: true,
                  },
                ]}
                data={salesEstimate?.cost_summary}
                striped
                dense
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={<ExpandedCostBreakdown row />}
                noHeader
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="no-print">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h3>Cost Summary</h3>
              <DataTable
                title=""
                columns={costSummaryColumns}
                data={salesEstimate?.cost_summary}
                striped
                dense
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={<ExpandedCostBreakdown row />}
                noHeader
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CostSummary;
