import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Frame } from '@shopify/polaris';
import { useIsAuthenticated } from '@azure/msal-react';
import Sidebar from './modules/navigation/components/Sidebar';
import UserTopNavigation from './modules/navigation/components/UserTopNavigation';

import { AuthenticationContext } from './modules/auth/contexts/AuthenticationContext';
import NewCustomerRooftopEstimate from './modules/estimateCustomers/pages/NewCustomerRooftopEstimate';
import ExistingCustomerRooftopEstimate from './modules/estimateCustomers/pages/ExistingCustomerRooftopEstimate';
import EditRooftopEstimateCustomer from './modules/estimateCustomers/pages/EditRooftopEstimateCustomer';
import ReviewAssets from './modules/estimateCustomers/pages/ReviewAssets';

import RecentEstimates from './modules/estimateDetails/pages/RecentEstimates';
import EstimateDetail from './modules/estimateDetails/pages/EstimateDetail';
import EstimateSpreadsheet from './modules/estimateSpreadsheet/pages/SimpleSpreadsheet';

import NewEquipment from './modules/estimateCosts/pages/NewEquipment';
import NewCraneAndCurb from './modules/estimateCosts/pages/NewCraneAndCurb';
import NewInstallationCosts from './modules/estimateCosts/pages/NewInstallationCosts';
import NewRebates from './modules/estimateRebates/pages/NewRebates';

import RebateRecordDetailPage from './modules/estimateRebates/pages/RebateRecordDetailPage';
import RebateRecordsListPage from './modules/estimateRebates/pages/RebateRecordsListPage';

import EquipmentCostsList from './modules/costSettings/pages/EquipmentCostsList';
import EquipmentCostEdit from './modules/costSettings/pages/EquipmentCostEdit';
import EquipmentCostNew from './modules/costSettings/pages/EquipmentCostNew';

import InstallationCostsList from './modules/costSettings/pages/InstallationCostsList';
import InstallationCostEdit from './modules/costSettings/pages/InstallationCostEdit';
import InstallationCostNew from './modules/costSettings/pages/InstallationCostNew';

import SystemAccessoriesList from './modules/adminSettings/pages/SystemAccessoriesList';
import SystemAccessoriesNew from './modules/adminSettings/pages/SystemAccessoriesNew';
import SystemAccessoriesEdit from './modules/adminSettings/pages/SystemAccessoriesEdit';

import JobCostsList from './modules/adminSettings/pages/JobCostsList';

import RebatesList from './modules/adminSettings/pages/RebatesList';
import RebateEdit from './modules/adminSettings/pages/RebateEdit';
import RebateNew from './modules/adminSettings/pages/RebateNew';

import MicrosoftAuthPage from './modules/auth/pages/MicrosoftAuthPage';
import LoginPage from './modules/auth/pages/LoginPage';

import UtilityGeniusForm from './modules/estimateRebates/components/UtilityGeniusForm';
import ChooseIncentives from './modules/estimateRebates/pages/ChooseIncentives';

import UtilityProvidersList from './modules/adminSettings/pages/UtilityProvidersList';
import UtilityProvidersDetail from './modules/adminSettings/pages/UtilityProvidersDetail';

import ZipCodesList from './modules/adminSettings/pages/ZipCodesList';
import ZipCodesDetail from './modules/adminSettings/pages/ZipCodesDetail';

import ServmanSqlLogsList from './modules/servmanDataLists/pages/ServmanSqlLogsList';
import ServmanCustomersList from './modules/servmanDataLists/pages/ServmanCustomersList';
import ServmanAssetsList from './modules/servmanDataLists/pages/ServmanAssetsList';
import ServmanEquipmentTypesList from './modules/servmanDataLists/pages/ServmanEquipmentTypesList';
import ServmanManufacturersList from './modules/servmanDataLists/pages/ServmanManufacturersList';
import ServmanContactTypesList from './modules/servmanDataLists/pages/ServmanContactTypesList';
import ServmanCustomerTypesList from './modules/servmanDataLists/pages/ServmanCustomerTypesList';
import ServmanPhoneTypesList from './modules/servmanDataLists/pages/ServmanPhoneTypesList';

import UserListPage from './modules/users/pages/UserListPage';
import UserEditPage from './modules/users/pages/UserEditPage';

import XoiJobsList from './modules/adminSettings/pages/XOiJobsList';

const App: React.FC = () => {
  const { user } = useContext(AuthenticationContext);
  const [mobileNavigationActive, setMobileNavigationActive] = React.useState<boolean>(false);
  const [permissionsLoading, setPermissionsLoading] = React.useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();
  const skipToContentRef = React.useRef<HTMLAnchorElement>(null);

  const ProtectedRoute = ({ component: Component, path, exact = true }) => (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isAuthenticated || user.id ? (
          <Frame
            topBar={<UserTopNavigation mobileNavigationActive={mobileNavigationActive} setMobileNavigationActive={setMobileNavigationActive} />}
            navigation={permissionsLoading ? <div /> : <Sidebar />}
            showMobileNavigation={mobileNavigationActive}
            onNavigationDismiss={() => setMobileNavigationActive(false)}
            skipToContentTarget={skipToContentRef}
          >
            <div style={{ padding: 30 }}>{permissionsLoading ? <div /> : <Component props={props} component />}</div>
          </Frame>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  // const AuthRoute = ({ component: Component, path }) => <Route path={path} render={(props) => (user.id === undefined ? <Component props={props} component /> : <Redirect to="/decodings" />)} />;
  const AuthRoute = ({ component: Component, path }) => <Route path={path} render={(props) => <Component props={props} component />} />;

  return isAuthenticated || user ? (
    <Router>
      <Switch>
        <ProtectedRoute exact path="/" component={() => <RecentEstimates />} />
        <ProtectedRoute exact path="/rooftop_automation/recent" component={() => <RecentEstimates />} />
        <ProtectedRoute exact path="/rooftop_automation/customers/new" component={() => <NewCustomerRooftopEstimate />} />
        <ProtectedRoute exact path="/rooftop_automation/customers/search" component={() => <ExistingCustomerRooftopEstimate />} />
        <ProtectedRoute exact path="/rooftop_automation/:estimateId/customer" component={() => <EditRooftopEstimateCustomer />} />
        <ProtectedRoute exact path="/rooftop_automation/:estimateId/system" component={() => <ReviewAssets />} />
        <ProtectedRoute exact path="/rooftop_automation/:estimateId/equipment" component={() => <NewEquipment />} />
        <ProtectedRoute exact path="/rooftop_automation/:estimateId/crane_curb" component={() => <NewCraneAndCurb />} />
        <ProtectedRoute exact path="/rooftop_automation/:estimateId/installation_costs" component={() => <NewInstallationCosts />} />
        <ProtectedRoute exact path="/rooftop_automation/:estimateId/rebates" component={() => <NewRebates />} />
        <ProtectedRoute exact path="/rooftop_automation/:estimateId/review" component={() => <EstimateDetail />} />
        <ProtectedRoute exact path="/rooftop_automation/:estimateId/spreadsheets" component={() => <EstimateSpreadsheet />} />

        <ProtectedRoute exact path="/admin/equipment_costs" component={() => <EquipmentCostsList />} />
        <ProtectedRoute exact path="/admin/equipment_costs/new" component={() => <EquipmentCostNew />} />
        <ProtectedRoute exact path="/admin/equipment_costs/:equipmentCostId/edit" component={() => <EquipmentCostEdit />} />

        <ProtectedRoute exact path="/admin/installation_costs" component={() => <InstallationCostsList />} />
        <ProtectedRoute exact path="/admin/installation_costs/new" component={() => <InstallationCostNew />} />
        <ProtectedRoute exact path="/admin/installation_costs/:installationCostId/edit" component={() => <InstallationCostEdit />} />

        <ProtectedRoute exact path="/admin/system_accessories" component={() => <SystemAccessoriesList />} />
        <ProtectedRoute exact path="/admin/system_accessories/new" component={() => <SystemAccessoriesNew />} />
        <ProtectedRoute exact path="/admin/system_accessories/:systemAccessoryId/edit" component={() => <SystemAccessoriesEdit />} />

        <ProtectedRoute exact path="/admin/job_costs" component={() => <JobCostsList />} />
        <ProtectedRoute exact path="/admin/job_costs/new" component={() => <JobCostsList />} />
        <ProtectedRoute exact path="/admin/:jobCostId/edit" component={() => <JobCostsList />} />

        <ProtectedRoute exact path="/admin/rebates" component={() => <RebatesList />} />
        <ProtectedRoute exact path="/admin/rebates/new" component={() => <RebateNew />} />
        <ProtectedRoute exact path="/admin/rebates/:rebateId/edit" component={() => <RebateEdit />} />
        <ProtectedRoute exact path="/rebate_records/:rebateRecordId" component={() => <RebateRecordDetailPage />} />
        <ProtectedRoute exact path="/rooftop_automation/rebate_records" component={() => <RebateRecordsListPage />} />

        <ProtectedRoute exact path="/admin/utility_providers" component={() => <UtilityProvidersList />} />
        <ProtectedRoute exact path="/admin/utility_providers/:providerId" component={() => <UtilityProvidersDetail />} />

        <ProtectedRoute exact path="/admin/zip_codes" component={() => <ZipCodesList />} />
        <ProtectedRoute exact path="/admin/zip_codes/:zipCode" component={() => <ZipCodesDetail />} />

        <ProtectedRoute exact path="/utility-genius-form" component={() => <UtilityGeniusForm />} />
        <ProtectedRoute exact path="/choose-incentives/:utilityId" component={() => <ChooseIncentives />} />

        <ProtectedRoute exact path="/admin/servman_sql_logs" component={() => <ServmanSqlLogsList />} />
        <ProtectedRoute exact path="/admin/servman_customers" component={() => <ServmanCustomersList />} />
        <ProtectedRoute exact path="/admin/servman_assets" component={() => <ServmanAssetsList />} />
        <ProtectedRoute exact path="/admin/servman_equipment_types" component={() => <ServmanEquipmentTypesList />} />
        <ProtectedRoute exact path="/admin/servman_manufacturers" component={() => <ServmanManufacturersList />} />
        <ProtectedRoute exact path="/admin/servman_contact_types" component={() => <ServmanContactTypesList />} />
        <ProtectedRoute exact path="/admin/servman_client_types" component={() => <ServmanCustomerTypesList />} />
        <ProtectedRoute exact path="/admin/servman_phone_types" component={() => <ServmanPhoneTypesList />} />

        <ProtectedRoute exact path="/admin/user_management" component={() => <UserListPage />} />
        <ProtectedRoute exact path="/admin/user_management/:userId/edit" component={() => <UserEditPage />} />

        <ProtectedRoute exact path="/xoi_jobs" component={() => <XoiJobsList />} />

        <AuthRoute path="/auth" component={() => <MicrosoftAuthPage />} />
        <AuthRoute path="/login" component={() => <LoginPage />} />
      </Switch>
    </Router>
  ) : (
    <div />
  );
};

export default App;
