import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InstallationCosts from '../components/InstallationCosts';
import SalesEstimateStepper from '../../estimateDetails/components/SalesEstimateStepper';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';
import EstimateInfoHeader from '../../estimateCustomers/components/EstimateInfoHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const NewInstallationCosts: React.FC = () => {
  const classes = useStyles();

  const [filterValues, setFilterValues] = useState({});

  const { estimateId } = useParams<{ estimateId: string }>();
  const { salesEstimate, fetchSalesEstimate } = useContext(EstimateDetailsContext);
  const { fetchInstallationCosts } = useContext(EstimateCostsContext);

  useEffect(() => {
    fetchSalesEstimate(estimateId);
    fetchInstallationCosts();
  }, []);

  return (
    <Box className={classes.box}>
      <SalesEstimateStepper activeStep={4} salesEstimateId={estimateId} />
      <Box>
        <Grid container>
          <Grid item xs={12} md={12}>
            <EstimateInfoHeader salesEstimate={salesEstimate} />
            <br />
            <h2 style={{ fontSize: '28px', marginBottom: 10 }}>Add Installation Costs</h2>
            <InstallationCosts salesEstimateId={estimateId} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewInstallationCosts;
